import React, { useEffect, useState } from 'react';

import { FormikValues, useFormik } from 'formik';

import {
  adminActions,
  AdminTypes,
  handleAll,
  MiscType,
  resetStatus,
  successOrSelector,
  successSelector,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useSelectedTabs from '../../../hooks/Admin/useSelectedTabs';
import useAdminListTabs from '../../../hooks/Admin/AdminList/useAdminListTabs';
import useUserNotFound from '../../../hooks/useUserNotFound';
import useTableSort from '../../../hooks/useTableSort';
import { AdminListTabTypes } from '../../../helpers/Constants';
import strings from '../../../localization';

import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';

import { showSuccess } from '../Profile/Pharmacist/PharmacistViewProfile/helper';

import AdminListHeader from './AdminListHeader';
import { adminsListingHeaders } from './helper';

const { approveAdmins, disapproveAdmins } = adminActions;
const successActions = [approveAdmins, disapproveAdmins];

const AdminList = () => {
  const dispatch = useAppDispatch();

  const success = useAppSelector((state) =>
    successSelector([adminActions.getActiveAdmins], state));
  const updateSuccess = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const { selectedTabs, handleTabClick } = useSelectedTabs(
    AdminListTabTypes.activeAdmins,
  );
  const { api, rows, list } = useAdminListTabs(selectedTabs);

  const [page, setPage] = useState(1);
  const { totalCount, data } = list;

  const { length } = data ?? [];
  const { showUserNotFound } = useUserNotFound(success, !!length);

  const { isDesc, orderBy, handleSorting, order } = useTableSort();

  const handleSubmit = (values: FormikValues) => {
    const { userType, userText } = values;

    setPage(1);

    dispatch(
      api({
        userText,
        page: 1,
        userType: handleAll(userType),
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      userText: '',
      userType: MiscType.All,
    },
    onSubmit: handleSubmit,
  });

  const { userText, userType } = formik.values;

  const handleReset = () => {
    formik.resetForm();
    setPage(1);
    dispatch(api({ page: 1 }));
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    const goToPage = selectedPage + 1;
    setPage(goToPage);
    dispatch(
      api({
        page: goToPage,
        userText,
        userType: handleAll<AdminTypes>(userType),
      }),
    );
  };

  const table: IDynamicTableObject = {
    headers: adminsListingHeaders(),
    rows,
    headerBar: [],
  };

  useEffect(() => {
    if (updateSuccess) {
      dispatch(api({ page, userText }));
      showSuccess(dispatch, strings.updatedSuccessfullyFormat);
      dispatch(resetStatus(successActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  useEffect(() => {
    dispatch(api({ page: 1 }));
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabs]);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      dispatch(
        api({
          page: 1,
          sortBy: orderBy,
          desc: isDesc,
          userText,
          userType: handleAll<AdminTypes>(userType),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  return (
    <DynamicTable
      table={table}
      loadSuccess={success}
      showHeader={false}
      page={page}
      totalCount={totalCount}
      order={order}
      orderBy={orderBy}
      handleSort={handleSorting}
      handlePagination={handlePagination}
      emptyContainerComponent={showUserNotFound()}
      customHeader={(
        <AdminListHeader
          handleReset={handleReset}
          selectedTab={selectedTabs}
          formik={formik}
          handleTabClick={handleTabClick}
        />
      )}
    />
  );
};

export default AdminList;
