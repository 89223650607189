import * as Yup from 'yup';

import { AllowancesNames } from '@pharmaplan/common';
import strings from '../../../localization';

const Validator = () =>
  Yup.object().shape({
    [AllowancesNames.fixedMealRate]: Yup.number().when(
      AllowancesNames.mealAllowanceType,
      {
        is: '2',
        then: () =>
          Yup.number()
            .typeError(strings.mustBeNumber)
            .required(strings.requiredField)
            .max(
              999.99,
            strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
            )
            .moreThan(
              0,
            strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
            ),
      },
    ),
    [AllowancesNames.fixedAccommodationRate]: Yup.number().when(
      AllowancesNames.accommodationAllowanceType,
      {
        is: '2',
        then: () =>
          Yup.number()
            .typeError(strings.mustBeNumber)
            .max(
              999.99,
            strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
            )
            .moreThan(
              0,
            strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
            )
            .required(strings.requiredField),
      },
    ),
    [AllowancesNames.fixedTravelAmount]: Yup.number().when(
      AllowancesNames.travelAllowanceType,
      {
        is: '2',
        then: () =>
          Yup.number()
            .typeError(strings.mustBeNumber)
            .max(
              999.99,
            strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
            )
            .moreThan(
              0,
            strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
            )
            .required(strings.requiredField),
      },
    ),
    [AllowancesNames.paidHourlyRate]: Yup.number()
      .typeError(
        strings.formatString(
          strings.numberValidation,
          strings.hourlyRate,
        ) as string,
      )
      .moreThan(
        0,
        strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
      )
      .max(
        999.99,
        strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
      )
      .required(strings.requiredField),
    startTime: Yup.string().required(strings.requiredField),
    endTime: Yup.string().required(strings.requiredField),
  });

export default Validator;
