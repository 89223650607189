import React, { useCallback, useEffect, useState } from 'react';

import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useFormik } from 'formik';

import {
  adminAllClients,
  adminCreateGroupChannel,
  adminGetAllClients,
  adminGetAllClientsPagination,
  chatActions,
  HelpTypes,
  ITEMS_PER_PAGE,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import debounce from 'lodash.debounce';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import genericClasses from '../../../../theme/GenericClasses';
import CustomTextField from '../../../common/CustomTextField';
import strings from '../../../../localization';
import styles from './styles';
import { resetDialog } from '../../../../reducers/dialogReducer';

const successAction = chatActions.adminCreateGroupChannel;
const userTypes = [HelpTypes.pharmacist, HelpTypes.pharmacy];

const CreateGroupDialog = () => {
  const dispatch = useAppDispatch();
  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const [page, setPage] = useState(1);

  const userList = useAppSelector(adminAllClients);
  const { data } = userList ?? {};

  const observerCallback = useCallback(
    (entries: { isIntersecting: any }[]) => {
      const paginationCondition = data.length >= page * ITEMS_PER_PAGE;

      if (paginationCondition && entries[0].isIntersecting) {
        setPage((prevPage) =>
          prevPage + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, data],
  );

  const { listEndRef } = useInfiniteScroll({ observerCallback });

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const { search } = formik.values ?? {};
  const searchUser = (input: string) => {
    if (input) {
      dispatch(
        adminGetAllClients({
          pagingModel: {
            page,
          },
          userTypes,
          userText: input.trim(),
          active: true,
        }),
      );
    }
  };

  const debouncedSearch = useCallback(debounce(searchUser, 300), []);

  useEffect(() => {
    dispatch(
      adminGetAllClients({ pagingModel: { page: 1 }, userTypes, active: true }),
    );
  }, []);

  useEffect(() => {
    debouncedSearch(search);
  }, [search]);

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        adminGetAllClientsPagination({
          pagingModel: {
            page,
          },
          active: true,
          userTypes,
          userText: search,
        }),
      );
    }
  }, [page]);

  const createGroup = (userId: string) =>
    () => {
      dispatch(adminCreateGroupChannel(userId));
    };

  useEffect(() => {
    if (success) {
      dispatch(resetStatus([successAction]));
      dispatch(resetDialog());
    }
  }, [success]);

  return (
    <Stack sx={styles.container}>
      <CustomTextField
        errorContainer={false}
        formik={formik}
        name="search"
        id="search"
        placeholder={strings.enterNameEmail}
      />
      <Box sx={styles.subContainer}>
        {data.map((item) => {
          const { userName, email, userId } = item ?? {};
          return (
            <Button
              key={userId}
              sx={styles.button}
              onClick={createGroup(userId)}
            >
              <Typography sx={styles.label}>
                {userName}
                {' '}
                (
                {email}
                )
              </Typography>
            </Button>
          );
        })}
        <div ref={listEndRef} style={genericClasses.paginationDiv} />
      </Box>
    </Stack>
  );
};

export default CreateGroupDialog;
