import { ITheme } from '.';

export default {
  styleOverrides: {
    input: {
      '&:-webkit-autofill': {
        'transition-delay': '9999s',
      },
    },
    root: ({ theme }: ITheme) =>
      ({
        maxHeight: '2.625em',
        borderRadius: '8.691px',
        backgroundColor: '#ffffff',
        fontSize: '14px',
        color: `${theme.palette.grey[800]}`,
        '&:hover fieldset': {
          borderColor: `${theme.palette.grey[300]} !important`,
        },
        '&.MuiInputBase-multiline': {
          maxHeight: 'initial',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: `${theme.palette.grey[300]}`,
        },
        '&.Mui-disabled': {
          backgroundColor: '#E1E4EF',
          border: 'none',
          pointerEvents: 'none',
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderWidth: '0px',
        },
        '&.Mui-disabled .MuiOutlinedInput-input': {
          '-webkit-text-fill-color': '#9CA5C0',
        },
        '& input::placeholder': {
          fontSize: '14px',
          fontWeight: 400,
        },
      }),
    notchedOutline: ({ theme }: ITheme) =>
      ({
        borderColor: `${theme.palette.grey[300]}`,
        borderWidth: '1px',
      }),
  },
};
