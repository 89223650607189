import {
  AdminAllowanceNames,
  PharmacyFilterWorkshifts,
  TypeOfUser,
  recurrenceType,
  HelpTypes,
  AdminTypes,
  MiscType,
  BroadcastNotificationType,
  PerformerTypes,
  TimeFormat,
} from '@pharmaplan/common';
import { DateTime } from 'luxon';
import strings from '../localization';
import ThemeConstants from '../theme/ThemeConstants';

export const staticWebURL = process.env.REACT_APP_STATIC_WEB_URL;
export const serverDateKey = 'serverDate';

export enum PrimaryAdminNavigation {
  dashboard = 'dashboard',
  configuration = 'configuration',
  admin = 'administrator',
  logs = 'logs',
  reports = 'reports',
  chat = 'chat',
}

export enum TextFieldVariants {
  filled = 'filled',
  outlined = 'outlined',
  standard = 'standard',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

const adminRoute = 'admin';
const pharmacyAdminRoute = 'pharmacy-admin';
const { dashboard, configuration, admin } = PrimaryAdminNavigation;

export enum SecondaryAdminNavigation {
  pharmaplanStatistics = 'pharmaplanStatistics',
  expressBooking = 'expressBooking',
  pendingActivation = 'pendingActivation',
  pendingCancellation = 'pendingCancellation',
  chat = 'chat',
  administratorList = 'administratorList',
  pharmacistsList = 'pharmacistsList',
  pharmacyList = 'pharmacyList',
  pharmacistsCapacities = 'pharmacistsCapacities',
  softwareList = 'softwareList',
  pharmacyChains = 'pharmacyChains',
  adminConfiguration = 'adminConfiguration',
  incompatibleMatch = 'incompatibleMatch',
  broadcastNotifications = 'broadcastNotifications',
  postedWorkshiftsReport = 'postedWorkshiftsReport',
  requestedReport = 'requestedReport',
  availabilityReport = 'availabilityReport',
  bookingReport = 'bookingReport',
  contactPreferences = 'contactPreferences',
  activityLogs = 'activityLogs',
  workshiftLogs = 'workshiftLogs',
  availabilityLogs = 'availabilityLogs',
  bookingLogs = 'bookingLogs',
  pharmacyAdministrators = 'pharmacyAdministrators',
}

export const Constants = {
  null: '',
  string: 'string',
  appName: 'PharmaPlan',
  formInputType: {
    text: 'text',
    dropdown: 'dropdown',
    radio: 'radio',
    upload: 'uploadFile',
    checkbox: 'checkbox',
    typography: 'typography',
    datePicker: 'datePicker',
    mapButton: 'mapButton',
    accordion: 'accordion',
    button: 'button',
    masked: 'masked',
    opreation: 'opreation',
    label: 'label',
    changeEmail: 'changeEmail',
    imageNameList: 'imageNameList',
    uploadIconLabel: 'uploadIconLabel',
  },
  input: {
    type: {
      text: 'text',
      password: 'password',
      number: 'number',
      alpha: 'alpha',
      file: 'file',
      time: 'time',
    },
  },

  variant: {
    contained: 'contained' as const,
    outlined: 'outlined' as const,
    text: 'text' as const,
  },
  color: {
    primary: 'primary',
    secondary: 'secondary',
  },
  upload: {
    type: {
      image: 'image',
      pdf: 'pdf',
      doc: 'doc',
    },
    ext: {
      image: '.jpg, .jpeg, .png',
      pdf: '.pdf',
      doc: '.doc .docx',
    },
  },
  paths: {
    profile: '/profile',
    myReplacements: '/my-replacements',
    dashboard: '/dashboard',
    calendarDashboard: '/dashboard/calendar',
    mapDashboard: '/dashboard/map',
    reports: '/reports',
    incomeReport: '/reports/income',
    telephone: 'tel:5145465200',
    login: '/login',
    signup: '/signup',
    help: '/help',
    resetPassword: '/resetpassword',
    setPassword: '/setpassword',
    changePassword: '/changePassword',
    otpForm: '/otpForm',
    checkEmail: '/checkemail',
    newPassword: '/newpassword',
    home: '/',
    notes: '/notes',
    history: '/history',
    signupFlow: '/signupFlow',
    setFlow: 'set/',
    setNewEmail: '/set/email',
    verifyOtp: '/set/verify-otp',
    addPassword: '/set/password',
    admin: {
      home: `/${adminRoute}/`,
      adminCalendarDashboard: `/${adminRoute}/${dashboard}/calendar`,
      createMultiple: `/${adminRoute}/${dashboard}/calendar/multiple-availabilities`,
      adminMapDashboard: `/${adminRoute}/${dashboard}/map`,
      dashboard: `/${adminRoute}/${dashboard}`,
      configuration: `/${adminRoute}/${configuration}`,
      softwareList: `/${adminRoute}/${configuration}/software-list`,
      pharmacyChains: `/${adminRoute}/${configuration}/pharmacy-chain`,
      pharmacistCapacities: `/${adminRoute}/${configuration}/pharmacist-capacities`,
      pendingActivationList: `/${adminRoute}/pending-activations`,
      pendingActivationListPharmacist: `/${adminRoute}/pending-activations/pharmacist`,
      pendingActivationListPharmacy: `/${adminRoute}/pending-activations/pharmacy`,
      pendingActivationListDeletedUsers: `/${adminRoute}/pending-activations/deletedUsers`,
      pendingCancellation: `/${adminRoute}/pending-cancellations`,
      contactPreferences: `/${adminRoute}/${configuration}/contact-preferences`,
      incompatibleMatch: `/${adminRoute}/${dashboard}/incompatible-matches`,
      updateIncompatibleMatch: `/${adminRoute}/${dashboard}/incompatible-matches/update`,
      admin: `/${adminRoute}/${admin}`,
      administratorList: `/${adminRoute}/${admin}/administrator-list`,
      pharmacistList: `/${adminRoute}/${admin}/pharmacist-list`,
      pharmaplanStatistics: `/${adminRoute}/${admin}/pharmaplan-statistics`,
      logs: `/${adminRoute}/logs`,
      reports: `/${adminRoute}/reports`,
      bookingReport: `/${adminRoute}/reports/booking-report`,
      availabilityReport: `/${adminRoute}/reports/availability-report`,
      requestedReport: `/${adminRoute}/reports/requested-report`,
      postedWorkshiftReport: `/${adminRoute}/reports/posted-workshift-report`,
      adminConfiguration: `/${adminRoute}/${configuration}/global-settings`,
      pharmacyList: `/${adminRoute}/${admin}/pharmacy-list`,
      createNewAdmin: `/${adminRoute}/${admin}/administrator-list/create-new-admin`,
      updateAdmin: `/${adminRoute}/${admin}/administrator-list/update-admin`,
      listOfWorkshifts: `/${adminRoute}/${admin}/pharmacy-list/list-of-workshifts`,
      viewRatings: `/${adminRoute}/${admin}/pharmacist-list/view-ratings`,
      pharmacyListIncompatible: `/${adminRoute}/${admin}/pharmacy-list/incompatible-matches`,
      pharmacyAdministrators: `/${adminRoute}/${admin}/pharmacy-administrators`,
      createPharmacyAdministrators: `/${adminRoute}/${admin}/pharmacy-admin/create`,
      updatePharmacyAdministrators: `/${adminRoute}/${admin}/pharmacy-admin/update`,
      appointPharmacyAdministrators: `/${adminRoute}/${admin}/pharmacy-admin/appoint`,
      appointPharmacyList: `/${adminRoute}/${admin}/pharmacies/appoint`,
      pharmacyListIncompatibleUpdate: `/${adminRoute}/${admin}/pharmacy-list/incompatible-matches/update`,
      broadcastList: `/${adminRoute}/${dashboard}/broadcast-list`,
      broadcastListGroups: `/${adminRoute}/${dashboard}/broadcast-list/groups`,
      broadcastListNotification: `/${adminRoute}/${dashboard}/broadcast-list/notifications`,
      createBroadcastGroup: `/${adminRoute}/${dashboard}/create/broadcast-group`,
      editBroadcastGroup: `/${adminRoute}/${dashboard}/edit/broadcast-group`,
      createNotification: `/${adminRoute}/${dashboard}/create/notification`,
      editNotification: `/${adminRoute}/${dashboard}/edit/notification`,
      activityLogs: `/${adminRoute}/logs/activity-logs`,
      workshiftLogs: `/${adminRoute}/logs/workshift-logs`,
      availabilityLogs: `/${adminRoute}/logs/availability-logs`,
      bookingLogs: `/${adminRoute}/logs/booking-logs`,
      profile: `/${adminRoute}/profile`,
      changePassword: `/${adminRoute}/change-password`,
      adminChat: `/${adminRoute}/chat`,
    },
    pharmacyAdmin: {
      home: `/${pharmacyAdminRoute}/`,
      profile: `/${pharmacyAdminRoute}/profile`,
      changePassword: `/${pharmacyAdminRoute}/change-password`,
      help: `/${pharmacyAdminRoute}/help`,
    },
    staticPages: {
      en: {
        termsOfServices: `${staticWebURL}/en/termsofservice.html`,
        privacyPolicy: `${staticWebURL}/en/privacypolicy.html`,
        siteMap: `${staticWebURL}/en/sitemap.html`,
        needReplacement: `${staticWebURL}/en/pharmacyowner.html`,
        aboutUs: `${staticWebURL}/en/about.html`,
      },
      fr: {
        termsOfServices: `${staticWebURL}/termsofservice.html`,
        privacyPolicy: `${staticWebURL}/privacypolicy.html`,
        siteMap: `${staticWebURL}/sitemap.html`,
        needReplacement: `${staticWebURL}/pharmacyowner.html`,
        aboutUs: `${staticWebURL}/about.html`,
      },
    },
  },
  allowancePlaceholder: '0.00',
  timePlaceholder: '00:00',
  priceSuffix: '$',
  telephone: '514-546-5200',
  zoomJarUrl: 'http://www.zoomjar.com/',
  cookieExpiry: 31536000,
};

export enum SocialIcons {
  google = 'google',
  facebook = 'facebook',
  apple = 'apple',
}

export enum InputTypes {
  text = 'text',
  email = 'email',
  password = 'password',
  number = 'number',
  alpha = 'alpha',
  file = 'file',
  postalCode = 'postalCode',
  time = 'time',
}

export enum CommonConstants {
  invalidDateMoment = 'Invalid Date',
}
export enum DynamicTableCell {
  text = 'text',
  icon = 'icon',
  textBox = 'textBox',
  favourite = 'favourite',
  incompatible = 'incompatible',
  bar = 'bar',
  status = 'status',
  notes = 'notes',
  date = 'date',
  button = 'button',
  link = 'link',
  chip = 'chip',
  customLink = 'customLink',
  customLinkList = 'customLinkList',
  smallerText = 'smallerText',
  smallerCustomLink = 'smallerCustomLink',
  iconButton = 'iconButton',
  checkbox = 'checkbox',
  customMenu = 'customMenu',
  labelValue = 'labelValue',
  userAndType = 'userAndType',
}

export enum SkeletonVariants {
  text = 'text',
  rectangular = 'rectangular',
  rounded = 'rounded',
  circular = 'circular',
}

export enum MomentTimeFormats {
  hrMinSeconds = 'hh:mm',
  hrMinSecondsH = 'hh:mm A',
  dayMonthYear = 'DD-MM-yyyy',
  yearMonthDateNumeric = 'YYYY-MM-DD',
  yearMonthDateNumericLuxon = 'yyyy-M-dd',
  yearMonthDateNumericLuxonStandard = 'yyyy-MM-dd',
  monthNameYear = 'MMM yyyy',
  weekdayMonthYear = 'ccc LLL dd, yyyy',
  dateMonthYear = 'dd LLL, yyyy',
  weekdayDate = 'ccc dd LLL, yyyy',
  dayMonthLong = 'ccc LLL dd yyyy',
  shortMonthName = 'MMM',
  fullmonthname = 'MMMM',
  dayOfTheMonth = 'd',
  nameOfTheWeekday = 'dddd',
  shortWeekDayName = 'ddd',
  monthDayYear = 'MM-DD-YYYY',
  fullYear = 'YYYY',
  hrMinute = 'HH:mm',
  fullDate = "yyyy-MM-dd'T'HH:mm:ss'Z'",
  month = 'LLLL',
  dayMonthName = 'dddd, MMM DD',
  dayOfWeek = 'c',
}

export enum ReplacementTableKeys {
  PharmacyId = 'PharmacyId',
  Logo = 'Logo',
  Location = 'Location',
  City = 'City',
  Favourite = 'Favourite',
  PharmacyName = 'PharmacyName',
  Notes = 'Notes',
}

export const PharmacyWorkshiftType = () =>
  [
    { key: PharmacyFilterWorkshifts.posted, name: strings.posted },
    { key: PharmacyFilterWorkshifts.booked, name: strings.booked },
    { key: PharmacyFilterWorkshifts.request, name: strings.requested },
    { key: PharmacyFilterWorkshifts.unfulfilled, name: strings.unfulfilled },
  ];

export const RoleMap = () =>
  ({
    30: strings.pharmacist,
  });

export enum CalendarNavigateKey {
  next = 'NEXT',
  prev = 'PREV',
}

export enum DashboardType {
  calendar = 'calendar',
  map = 'map',
}

export enum CalendarStateKey {
  month = 'month',
  day = 'day',
  week = 'week',
}

export enum CalendarMapKey {
  map = 'MAP',
  calendar = 'CALENDAR',
}

export enum WorkshiftDetailsItemType {
  single = 'single',
  multiple = 'multiple',
}
export enum MomentFormatOptions {
  shortMonthName = 'MMM',
  fullmonthname = 'MMMM',
  dayOfTheMonth = 'DD',
  nameOfTheWeekday = 'dddd',
  shortWeekDayName = 'ddd',
  monthDayYear = 'MM-DD-YYYY',
  fullYear = 'YYYY',
  hrMinute = 'HH:mm',
  dayMonthName = 'dddd, MMM DD',
  fullDate = 'ddd, Do MMM YYYY',
}

export enum WorkTimings {
  start = '9:00',
  end = '18:00',
}
export enum DateFormats {
  fullDate = 'ccc, d MMM yyyy',
  hourMinutes = 'HH:mm',
  twelveHours = 'h:mm a',
  completeDate = 'cccc, MMMM dd, yyyy',
  hourMinutesSeconds = 'HH:mm:ss',
  twelveHoursSeconds = 'h:mm:ss a',
}
export enum paginationElementType {
  previous = 'previous',
  next = 'next',
  first = 'first',
  last = 'last',
}
export enum Ordinals {
  first = 'first',
  second = 'second',
  third = 'third',
  fourth = 'fourth',
}

export enum DurationTypes {
  day = 'day',
  month = 'month',
  notRepeat = 'notRepeat',
  week = 'week',
  weekday = 'weekday',
  weekend = 'weekend',
}

export enum MonthlyRecurrenceDropdown {
  fourthDayOfTheWeek = 'weekday',
  monthlyDate = 'monthly',
}

export enum LoginType {
  resetPassword = 'resetPassword',
  fourthDayOfTheWeek = 'weekday',
  monthlyDate = 'monthly',
}

export enum MonthlyYearlyRecurrenceDropdown {
  onDayOfTheMonth = 'onDay',
  onTheMontheOfTheYear = 'onThe',
}

export const RepeatMap = {
  [DurationTypes.notRepeat]: recurrenceType.Never,
  [DurationTypes.day]: recurrenceType.EveryDay,
  [DurationTypes.week]: recurrenceType.EveryWeek,
  [DurationTypes.weekday]: recurrenceType.EveryWeekday,
  [DurationTypes.month]: recurrenceType.EveryMonth,
  [DurationTypes.weekend]: recurrenceType.Weekend,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InvertedRepeatMap = Object.fromEntries(
  Object.entries(RepeatMap).map((a) =>
    a.reverse()),
);

export enum OutcomeModalTypes {
  success = 'success',
  error = 'error',
  congrats = 'congrats',
  noCancellation = 'noCancellation',
  selfServiceCancelConfirmation = 'selfServiceCancelConfirmation',
  bookingPending = 'bookingPending',
  updateTiming = 'updateTiming',
  requestSuccess = 'requestSuccess',
  submitSuccess = 'submitSuccess',
  softwareWarning = 'softwareWarning',
  activationSuccess = 'activationSuccess',
  deletionSuccess = 'deletionSuccess',
  justSuccess = 'justSuccess',
  adminCreatedSuccess = 'adminCreatedSuccess',
  sessionReset = 'sessionReset',
  sessionTimeOut = 'sessionTimeOut',
  pharmacyAdminAppointedSuccess = 'pharmacyAdminAppointedSuccess',
}

export enum NavType {
  notes = 'notes',
  history = 'history',
}

export const statusMap = () =>
  ({
    [strings.notAssigned]: ThemeConstants.pharmacists.workshift,
    [strings.pending]: ThemeConstants.pharmacists.pending,
    [strings.booked]: ThemeConstants.pharmacists.notAssigned,
    [strings.cancelled]: ThemeConstants.pharmacists.cancelled,
    [strings.requested]: ThemeConstants.pharmacists.requested,
    [strings.unfulfilled]: ThemeConstants.grey[800],
    [strings.posted]: ThemeConstants.pharmacists.workshift,
  });

export const statusLabelMap = () =>
  ({
    Pending: strings.pending,
    Refused: strings.notAssigned,
    Accepted: strings.booked,
    Canceled: strings.cancelled,
    booked: strings.booked,
    requested: strings.requested,
    unfulfilled: strings.unfulfilled,
    posted: strings.posted,
  });

export const SoftwareExperience = () =>
  [
    strings.unfamiliar,
    strings.beginner,
    strings.good,
    strings.veryGood,
    strings.expert,
  ];

export const days = () =>
  [
    strings.monday,
    strings.tuesday,
    strings.wednesday,
    strings.thursday,
    strings.friday,
    strings.saturday,
    strings.sunday,
  ];

export const preferredDaysMap = () =>
  [
    strings.sunday,
    strings.monday,
    strings.tuesday,
    strings.wednesday,
    strings.thursday,
    strings.friday,
    strings.saturday,
  ];

export const daysDropdown = () =>
  days().map((item, index) =>
    ({
      key: ((index + 1) % 7).toString(),
      label: item,
    }));

export const ReplacementTypes = () =>
  [strings.pharmacist, strings.technicians];

export const telephoneInputMask = '999-999-9999';

export type WhiteSpace =
  | '-moz-pre-wrap'
  | 'break-spaces'
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-line'
  | 'pre-wrap';

export const ReplacementType = () =>
  [
    { label: strings.pharmacist, key: TypeOfUser.pharmacist },
  ];

export const RecurrenceTypes = () =>
  [
    { key: DurationTypes.notRepeat, label: strings.never },
    { key: DurationTypes.day, label: strings.daily },
    {
      key: DurationTypes.week,
      label: strings.weekly,
    },
    {
      key: DurationTypes.weekday,
      label: strings.weekdaySpace,
    },
    {
      key: DurationTypes.weekend,
      label: strings.weekend,
    },
    {
      key: DurationTypes.month,
      label: strings.monthly,
    },
  ];

export enum ChipType {
  hourlyRate = 'hourlyRate',
  pharmacyHourlyRate = 'pharmacyHourlyRate',
}

export const repeatEvery = () =>
  [
    strings.never,
    strings.everyDay,
    strings.everyWeekday,
    strings.everyWeek,
    strings.everyMonth,
    strings.everyYear,
  ];

export enum ScreenTypes {
  serviceWorkshiftDetails = 'serviceWorkshiftDetails',
  addWorkshift = 'addWorkshift',
  pharmacistProfile = 'pharmacistProfile',
  workshiftSummary = 'workshiftSummary',
  updateWorkshift = 'updateWorkshift',
  fullServiceUpdateWorkshift = 'fullServiceUpdateWorkshift',
  addAvailability = 'addAvailability',
  updateAvailability = 'updateAvailability',
  availableWorkshift = 'availableWorkshift',
  requestedList = 'requestedList',
  bookingList = 'bookingList',
  profile = 'profile',
  pendingCancellation = 'pendingCancellation',
  pharmacistList = 'pharmacistList',
  pharmacyList = 'pharmacyList',
  adminWatchlists = 'adminWatchlists',
  broadcastList = 'broadcastList',
  createVacation = 'createVacation',
  replacement = 'replacement',
}

export enum BreakPoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export const isIpad = typeof navigator !== 'undefined'
  && /iPad|iPhone|iPod/.test(navigator.userAgent);

export enum profileTypes {
  coordinates = 'coordinates',
  general = 'general',
  contacts = 'contacts',
  myLocationOnMap = 'myLocationOnMap',
  geographicAvailability = 'geographicAvailability',
  aboutUs = 'aboutUs',
  privacyPolicy = 'privacyPolicy',
}

export enum formType {
  single = 'single',
  multiple = 'multiple',
  table = 'table',
}

export const postalCodeRegExp = /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] ?\d[A-Z]\d$/;
export const onlyNumbersRegex = /^\d+$/g;
export const nameRegex = /^(\D*)$/g;
export const phoneRegex = /\d*-\d*/g;
export const mapUrl = (latitude: string, longitude: string) =>
  `http://www.google.com/maps/place/${latitude},${longitude}`;

export const AllowanceAdminConfig = [
  {
    key: 1,
    label: strings.travelAllowances,
    name: AdminAllowanceNames.travelAllowance,
  },
  {
    key: 2,
    label: strings.mealAllowances,
    name: AdminAllowanceNames.mealAllowance,
  },
  {
    key: 3,
    label: strings.accommodationAllowances,
    name: AdminAllowanceNames.accommodationAllowance,
  },
  {
    key: 4,
    label: strings.emergencyServiceFees,
    name: AdminAllowanceNames.emergencyFees,
  },
];

export enum TypeOfSoftwareExpertise {
  softwareExpertise = 'softwareExpertise',
  softwareFamilarity = 'softwareFamilarity',
}

export enum OtherScreens {
  DeletedPendingUsers = 'DeletedPendingUsers',
  DeletedPharmacistList = 'DeletedPharmacistList',
  DeletedPharmacyList = 'DeletedPharmacyList',
  MatchingAvailabilities = 'MatchingAvailabilities',
  AdminMap = 'AdminMap',
  AdminCalendar = 'AdminCalendar',
  AdminNotes = 'AdminNotes',
  AdminAvailabilites = 'AdminAvailabilites',
  MatchingWorkshifts = 'MatchingWorkshifts',
  AdminPharmacistBookingList = 'AdminPharmacistBookingList',
  PharmacistList = 'PharmacistList',
  PharmacyList = 'PharmacyList',
  incompatibleMatches = 'incompatibleMatches',
  AdminBookingReports = 'AdminBookingReports',
  AdminAvailabilityReports = 'AdminAvailabilityReports',
  AdminPostedWorkshiftReports = 'AdminPostedWorkshiftReports',
  AdminRequestedReports = 'AdminRequestedReports',
  AdminReports = 'AdminReports',
  ListOfWorkshifts = 'ListOfWorkshifts',
  BroadcastGroups = 'BroadcastGroups',
  AppointedPharmacies = 'AppointedPharmacies',
  Replacements = 'Replacements',
}

export enum PendingCancellationTabTypes {
  pendingCancellations = 'pendingCancellations',
  cancelledBookings = 'cancelledBookings',
  rejectedCancellations = 'rejectedCancellations',
}

export enum PharmacistListTabTypes {
  activePharmacists = 'activePharmacists',
  deactivatedPharmacists = 'deactivatedPharmacists',
  hiddenPharmacists = 'hiddenPharmacists',
  deletedPharmacists = 'deletedPharmacists',
  detailsSearch = 'detailsSearch',
}

export enum ConfigurationSuccessTypes {
  pharmacistCapacity = 'pharmacistCapacity',
  softwareList = 'softwareList',
  pharmacyChains = 'pharmacyChains',
  contactPreferences = 'contactPreferences',
}

export enum DeactivateModalIcon {
  warning = 'warning',
  cancelBooking = 'cancelBooking',
  success = 'success',
  positiveConfirmation = 'positiveConfirmation',
}

export enum PharmacyListTabTypes {
  activePharmacies = 'activePharmacies',
  deactivatedPharmacies = 'deactivatedPharmacies',
  appointedPharmacies = 'appointedPharmacies',
  deletedPharmacies = 'deletedPharmacies',
}

export enum customDropdownTypes {
  All = 'All',
}
export enum AdminListTabTypes {
  activeAdmins = 'activeAdmins',
  inactiveAdmins = 'inactiveAdmins',
}

export const adminTypeMap = ['', strings.admin, strings.superadmin];

export const adminDropdown = [
  { key: MiscType.All, label: strings.all },
  { key: AdminTypes.admin, label: strings.admin },
  { key: AdminTypes.superadmin, label: strings.superadmin },
];

export enum DeleteConfirmationType {
  pharmacyChains = 'pharmacyChains',
  softwares = 'softwares',
  pharmacistCapacities = 'pharmacistCapacities',
  contactPreferences = 'contactPreferences',
}

export enum AdminNotesType {
  pharmacy = 'pharmacy',
  pharmacist = 'pharmacist',
}

export const todaysDateISO = () =>
  DateTime.now().toISO({ includeOffset: false });

export enum ListOfWorkshiftsTabTypes {
  upcomingWorkshifts = 'upcomingWorkshifts',
  pastWorkshifts = 'pastWorkshifts',
}

export const noDataPlaceholder = '-';

export enum BroadcastTabsType {
  groups = 'groups',
  notifications = 'notifications',
}

export enum BroadcastGroupActionType {
  create = 'create',
  edit = 'edit',
}

export const UserTypesDropdown = [
  { key: MiscType.All, label: strings.all },
  { key: HelpTypes.pharmacy, label: strings.pharmacy },
  { key: HelpTypes.pharmacist, label: strings.pharmacist },
  { key: HelpTypes.pharmacyAdmin, label: strings.pharmacyAdministrator },
];

const { email, message, pushNotification } = BroadcastNotificationType;

export const BroadcastNotificationMap = {
  [email]: strings.email,
  [message]: strings.message,
  [pushNotification]: strings.pushNotification,
};

export const BroadcastNotificationDropdown = [
  { key: email, label: strings.email },
  { key: message, label: strings.message },
];

export const performerTypeMapper = {
  [PerformerTypes.admin]: strings.admin,
  [PerformerTypes.superAdmin]: strings.superadmin,
  [PerformerTypes.pharmacist]: strings.pharmacist,
  [PerformerTypes.pharmacy]: strings.pharmacy,
  [PerformerTypes.pharmacyAdmin]: strings.pharmacyAdministrator,
};

export const timeLimits = {
  [TimeFormat.twelveHours]: {
    startMin: '8:00 am',
    startMax: '6:00 pm',
    endMax: '10:00 pm',
  },
  [TimeFormat.twentyFourHours]: {
    startMin: '8:00',
    startMax: '18:00',
    endMax: '22:00',
  },
};

export enum ListType {
  left = 'left',
  right = 'right',
}

export enum PharmacyAdminActionTypes {
  create = 'create',
  edit = 'edit',
  appoint = 'appoint',
}
export const geolocation = 'geolocation';
export const granted = 'granted';

export enum PharmacyActionsBasedStatKey {
  createWorkshift = 'createWorkshift',
  updateWorkshift = 'updateWorkshift',
  deleteWorkshift = 'deleteWorkshift',
  updatePharmacy = 'updatePharmacy',
}
export enum PharmacistActionsBasedStatKey {
  createAvailability = 'createAvailability',
  updateAvailability = 'updateAvailability',
  requestBooking = 'requestBooking',
  updatePharmacist = 'updatePharmacist',
}

export enum approvedPendingStatsKey {
  pending = 'pending',
  approved = 'approved',
}

export enum totalWorkshiftStatsKeys {
  posted = 'posted',
  requested = 'requested',
  booked = 'booked',
  totalContractValue = 'totalContractValue',
}

export enum FinancialStatsKey {
  pharmacyBilling = 'pharmacyBilling',
  pharmacistPayout = 'pharmacistPayout',
  grossProfit = 'grossProfit',
  totalBookedHours = 'totalBookedHours',
}

export enum AverageFinancialKey {
  averagePharmacyRate = 'averagePharmacyRate',
  averagePharmacistRate = 'averagePharmacistRate',
  averageHourlyProfit = 'averageHourlyProfit',
}

export const quarters = [
  { key: 1, label: `${strings.quarter} 1` },
  { key: 2, label: `${strings.quarter} 2` },
  { key: 3, label: `${strings.quarter} 3` },
  { key: 4, label: `${strings.quarter} 4` },
];

export const myColorSet = {
  '--sendbird-light-primary-500': '#48A14D',
  '--sendbird-light-primary-400': '#48A14D',
  '--sendbird-light-primary-300': '#48A14D',
  '--sendbird-light-primary-200': '#48A14D',
  '--sendbird-light-primary-100': '#48A14D',
};

export const myStringSet = {
  PLACE_HOLDER__NO_CHANNEL: strings.clickOnUserToDisplayChat,
  PLACE_HOLDER__NO_MESSAGES: strings.noMessages,
  MESSAGE_INPUT__PLACE_HOLDER: strings.messageGuide,
  MESSAGE_INPUT__PLACE_HOLDER__DISABLED: strings.customerIsAlreadyEngaged,
};

export type IChannelURLData = {
  users: string;
  user_type: string;
  personnelId: string;
  username: string;
};

export type IChannelData = {
  [key: string]: IChannelURLData;
};

export enum AvailabilityVacationTabs {
  vacation,
  availability,
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export const dateAndTime = () =>
  `${strings.date} | ${strings.time}`;
