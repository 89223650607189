import * as Yup from 'yup';
import { AdminAllowanceNames, AllowancesNames } from '@pharmaplan/common';
import strings from '../../../../localization';

const Validator = () =>
  Yup.object().shape({
    [AdminAllowanceNames.mealAllowance]: Yup.number()
      .typeError(strings.mustBeNumber)
      .max(
        999.99,
        strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
      )
      .moreThan(
        0,
        strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
      ),
    [AdminAllowanceNames.accommodationAllowance]: Yup.number()
      .typeError(strings.mustBeNumber)
      .max(
        999.99,
        strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
      )
      .moreThan(
        0,
        strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
      ),
    [AdminAllowanceNames.travelAllowance]: Yup.number()
      .typeError(strings.mustBeNumber)
      .moreThan(
        0,
        strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
      )
      .max(
        999.99,
        strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
      ),

    [AllowancesNames.paidHourlyRate]: Yup.number()
      .typeError(strings.mustBeNumber)
      .required(strings.requiredField)
      .moreThan(
        0,
        strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
      )
      .max(
        999.99,
        strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
      ),

    [AllowancesNames.emergencyFees]: Yup.number()
      .typeError(strings.mustBeNumber)
      .moreThan(
        0,
        strings.formatString(strings.pleaseEnterMoreThan, '0') as string,
      )
      .max(
        999.99,
        strings.formatString(strings.pleaseEnterLessThan, '1000') as string,
      ),
    startTime: Yup.string().required(strings.requiredField),
    endTime: Yup.string().required(strings.requiredField),
  });

export default Validator;
