import { createReducer } from '@reduxjs/toolkit';
import {
  addAssignedChannels,
  createGroupChannel,
  logout,
  setSelectedClient,
  setUnreadCount,
} from '../actions';

export interface IChatReducerState {
  channelUrl: string;
  clientUnreadCount: number;
  adminUnreadCount: number;
  assignedChannels: Array<string>;
  userUnreadCount: number;
  selectedClient: string;
}

export const chatInitialState: IChatReducerState = {
  channelUrl: '',
  clientUnreadCount: 0,
  adminUnreadCount: 0,
  assignedChannels: [],
  userUnreadCount: 0,
  selectedClient: '',
};

const chatReducer = createReducer(chatInitialState, (builder) =>
  builder
    .addCase(createGroupChannel.fulfilled, (state, action) => {
      state.channelUrl = action.payload;
    })
    .addCase(addAssignedChannels, (state, action) => {
      state.assignedChannels = action.payload;
    })
    .addCase(setUnreadCount, (state, action) => {
      state.userUnreadCount = action.payload;
    })
    .addCase(setSelectedClient, (state, action) => {
      state.selectedClient = action.payload;
    })
    .addCase(logout.fulfilled, (state, action) => {
      return chatInitialState;
    })
);

export default chatReducer;
