import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  successOrSelector,
  pharmacistActions,
  getReplacementPDF,
  resetStatus,
  TypeOfUser,
  IReplacementsData,
  IPharmacyReplacementData,
  getPharmacyReplacementPdf,
  pharmacyActions,
  getPharmacyReplacementsWeb,
  ITEMS_PER_PAGE,
  getPharmacistReplacementsWeb,
  ToUserTypings,
} from '@pharmaplan/common';

import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import strings from '../../localization';
import { downloadPDF, setPageTitle } from '../../helpers/Functions';
import ResponsiveClasses from '../../theme/ResponsiveClasses';
import useGetItems from '../../hooks/useGetItems';
import useSelfService from '../../hooks/useSelfService';
import { resetDialog } from '../../reducers/dialogReducer';
import useUser from '../../hooks/useUser';

import DynamicTable from '../DynamicTable';
import AdsContainer from '../common/AdsContainer';
import DownloadPDF from '../common/DownloadPDF';

import {
  headers,
  pharmacistHeaders,
  pharmacistReplacementMapper,
  pharmacyReplacementMapper,
} from './helpers';
import { renderScreen, setOpen } from '../../actions/drawerActions';
import { OtherScreens, ScreenTypes } from '../../helpers/Constants';
import Drawer from '../Drawer';
import useTableSort from '../../hooks/useTableSort';

const successActions = [
  pharmacyActions.addIncompatible,
  pharmacyActions.removeIncompatible,
];

const MyReplacements = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getImage } = useGetItems();
  const { isSelfService } = useSelfService();
  const { isDesc, orderBy, handleSorting, order } = useTableSort();

  const goToPharmacistDetails = (pharmacistId: string) =>
    () => {
      dispatch(setOpen(true));
      dispatch(
        renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.replacement,
          pharmacistId,
        }),
      );
    };

  const goToPharmacistRating = (pharmacistId: string) =>
    () => {
      dispatch(setOpen(true));
      dispatch(
        renderScreen({
          screenNumber: 4,
          screenType: ScreenTypes.workshiftSummary,
          pharmacistId,
          fromScreen: OtherScreens.Replacements,
        }),
      );
    };

  const loadSuccess = useAppSelector((state) =>
    successOrSelector(
      [pharmacistActions.replacementsWeb, pharmacyActions.replacementsWeb],
      state,
    ));

  const pdfSuccess = useAppSelector((state) =>
    successOrSelector(
      [pharmacistActions.getReplacementPDF, pharmacyActions.replacementsPdf],
      state,
    ));

  const success = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const { replacements: repl, pdf, userType } = useUser();

  const [page, setPage] = useState(1);

  const currentIndex = (page - 1) * ITEMS_PER_PAGE;

  const isPharmacist = userType === TypeOfUser.pharmacist;

  const userConfig = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      title: strings.pharmaciesServed,
      headers: pharmacistHeaders(),
      rows: pharmacistReplacementMapper(
        repl.data as IReplacementsData[],
        navigate,
        currentIndex,
        getImage,
      ),
      request: getPharmacistReplacementsWeb,
      pdfRequest: getReplacementPDF,
    },
    [TypeOfUser.pharmacy]: {
      title: strings.formatString(
        strings.myReplacementUser,
        strings.pharmacistsLower,
      ),
      headers: headers(isSelfService),
      rows: pharmacyReplacementMapper(
        repl.data as IPharmacyReplacementData[],
        navigate,
        currentIndex,
        goToPharmacistDetails,
        goToPharmacistRating,
      ),
      request: getPharmacyReplacementsWeb,
      pdfRequest: getPharmacyReplacementPdf,
    },
  });

  const handleAPI = (sortBy?: string, desc?: boolean) => {
    if (isPharmacist) {
      dispatch(
        getPharmacistReplacementsWeb({
          page: 1,
          sortBy,
          desc,
        }),
      );
      return;
    }

    dispatch(
      getPharmacyReplacementsWeb({
        page: 1,
        sortBy,
        desc,
      }),
    );
  };

  const Table = {
    title: userConfig[userType].title as string,
    headers: userConfig[userType].headers,
    headerBar: [
      {
        key: '1',
        Component: (
          <DownloadPDF
            onClick={() =>
              dispatch(userConfig[userType].pdfRequest())}
          />
        ),
      },
    ],
    rows: userConfig[userType].rows,
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    setPage(selectedPage + 1);
    dispatch(userConfig[userType].request(selectedPage + 1));
  };

  useEffect(() => {
    if (pdfSuccess) {
      downloadPDF(pdf.pdfContent, 'replacements.pdf');
      dispatch(
        resetStatus([
          pharmacistActions.getReplacementPDF,
          pharmacyActions.replacementsPdf,
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfSuccess, pdf.pdfContent]);

  useEffect(() => {
    setPageTitle(strings.myPharmacies);
    handleAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      handleAPI();
      dispatch(resetDialog());
      dispatch(resetStatus(successActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      handleAPI(orderBy, isDesc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order, userType]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={10}>
        <DynamicTable
          table={Table}
          page={page}
          loadSuccess={loadSuccess}
          count={repl.count}
          handlePagination={handlePagination}
          totalCount={repl.totalCount}
          order={order}
          orderBy={orderBy}
          handleSort={handleSorting}
        />
      </Grid>
      <Grid sx={ResponsiveClasses.hideOnLg} item xs={2}>
        <AdsContainer />
      </Grid>
      <Drawer />
    </Grid>
  );
};

export default MyReplacements;
