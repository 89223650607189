import React, { useEffect, useState } from 'react';

import {
  BookingState,
  ICalendarEventType,
  PharmacistLegendVariant,
  PharmacyLegendVariant,
  RecurrenceActions,
  TypeOfUser,
  getNotification,
  getNotificationState,
  getTypeOfUser,
  notificationActions,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { INotificationsData } from '@pharmaplan/common/models/NotificationsModel';

import { useNavigate } from 'react-router-dom';
import useDrawerNavigation from './useDrawerNavigation';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { setDialog } from '../reducers/dialogReducer';
import OutcomeModal from '../components/Modals/OutcomeModal';
import { Constants, OutcomeModalTypes } from '../helpers/Constants';
import strings from '../localization';
import { getTodayWithoutOffset } from '../components/Reports/helpers';
import useSelfService from './useSelfService';

const { series } = RecurrenceActions;
const { pharmacist, pharmacy } = TypeOfUser;
const { error: errorType } = OutcomeModalTypes;

const { requested, posted, booked: pharmacyBooked } = PharmacyLegendVariant;
const { booked } = PharmacistLegendVariant;
const { PendingWithPharmacist } = BookingState;

const statusMap = [posted, requested, booked];
const { calendarDashboard } = Constants.paths;

const initValue = {
  isRequested: false,
  item: null,
};
interface INotificationItem {
  isRequested: boolean;
  item: null | INotificationsData;
}

const useNotification = (closeNotification: () => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { openDrawer } = useDrawerNavigation();
  const { isSelfService } = useSelfService();
  const visitSuccess = useAppSelector((state) =>
    successSelector([notificationActions.getNotification], state));

  const userType = useAppSelector(getTypeOfUser);
  const notificationState = useAppSelector(getNotificationState);
  const [notification, setNotification] = useState<INotificationItem>(initValue);

  const isPharmacy = isSelfService && userType === pharmacy;
  const isPharmacist = userType === pharmacist;

  const commonDrawerOpen = (
    id: string,
    inBookingState: BookingState,
    inType: ICalendarEventType,
  ) => {
    openDrawer({
      eventIds: [id],
      type: inType,
      bookingState: inBookingState,
      recurrenceType: series,
      recurrenceId: '',
    });
  };

  const visitNotifications = (notificationItem: INotificationsData) => {
    const { notificationId: id } = notificationItem;
    dispatch(getNotification(id));
    setNotification({ isRequested: true, item: notificationItem });
  };

  const showPastDateError = () => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <OutcomeModal type={errorType} message={strings.expiredError} />
        ),
        showCloseButton: true,
      }),
    );
  };

  const handleNotification = () => {
    const { startDate } = notification.item ?? { startDate: '' };
    const { bookingId, bookingState, status, workshiftId, isAccessible } = notificationState;
    const isPendingPharmacist = bookingState === PendingWithPharmacist || isPharmacist;

    const type = statusMap[status as keyof typeof statusMap];

    const isPastDate = startDate < getTodayWithoutOffset();

    if (isPastDate || !isAccessible) {
      showPastDateError();
    } else {
      navigate(calendarDashboard);
      switch (type) {
        case posted:
          if (isPharmacy) {
            commonDrawerOpen(workshiftId, bookingState, type);
          } else if (isPharmacist) {
            openDrawer({
              eventIds: [workshiftId],
              type: PharmacistLegendVariant.workshift,
            });
          }
          break;
        case requested:
          if (isPendingPharmacist) {
            openDrawer({ eventIds: [bookingId], type });
          } else {
            commonDrawerOpen(workshiftId, bookingState, type);
          }
          return;
        case pharmacyBooked:
          openDrawer({ eventIds: [bookingId], type, bookingState });
          break;
        default:
          openDrawer({
            eventIds: [bookingId],
            type: type as PharmacistLegendVariant,
            bookingState,
          });
          break;
      }
    }
  };

  useEffect(() => {
    if (visitSuccess && notification.isRequested) {
      dispatch(resetStatus([notificationActions.getNotification]));
      setNotification(initValue);
      handleNotification();
      closeNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitSuccess]);

  return { handleActions: visitNotifications };
};

export default useNotification;
