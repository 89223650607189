import { useEffect, useMemo } from 'react';

import { FormikValues } from 'formik';
import { useLocation } from 'react-router-dom';

import {
  appointExistingPharmacy,
  createPharmacyAdmin,
  editPharmacyAdmin,
  getPharmacyAdminDetails,
  getPharmacyDetails,
  pharmaciesForPharmacyAdmin,
  pharmacyAdminDetails,
  pharmacyDetails,
} from '@pharmaplan/common';

import { PharmacyAdminActionTypes } from '../../../helpers/Constants';
import strings from '../../../localization';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';

const baseValues = {
  name: '',
  description: '',
  email: '',
  languageId: '',
  phone: '',
  mobile: '',
  address: '',
  city: '',
  province: '',
  pharmacyIds: [],
  postalCode: '',
};

interface IUseHandleEditAppoint {
  type: PharmacyAdminActionTypes;
}

const { create, edit, appoint } = PharmacyAdminActionTypes;

const useHandleEditAppoint = ({ type }: IUseHandleEditAppoint) => {
  const dispatch = useAppDispatch();

  const pharmacyAdmin = useAppSelector(pharmacyAdminDetails);
  const pharmacyDetail = useAppSelector(pharmacyDetails);

  const pharmaciesList = useAppSelector(pharmaciesForPharmacyAdmin);
  const { data } = pharmaciesList ?? {};

  const isEdit = type === edit;
  const notCreate = type !== create;
  const isAppoint = type === appoint || !!pharmacyAdmin.pharmacyCompany.appointedPharmacyId;

  const { state } = useLocation();
  const { id } = state ?? {};

  const { pharmacyCompany, pharmacies } = pharmacyAdmin ?? {};
  const { email, languageId } = pharmacyCompany?.user ?? {};

  const filteredData = () => {
    if (isAppoint) {
      return data.filter((item) =>
        item.pharmacyId !== id);
    }
    return data;
  };

  const company = {
    ...pharmacyCompany,
    languageId,
    email,
  };

  const initialValues = {
    [edit]: company,
    [create]: baseValues,
    [appoint]: {
      ...pharmacyDetail.pharmacy,
      mobile: '',
      description: '',
    },
  };

  const title = {
    [edit]: strings.editPharmacyAdmin,
    [create]: strings.createNewPharmacyAdmin,
    [appoint]: strings.appointExistingPharmacy,
  };

  const selectedPharmacies = () => {
    if (isEdit) {
      return pharmacies;
    }
    return [];
  };

  const handleSubmit = (values: FormikValues, pharmacyIds: Array<string>) => {
    const {
      name,
      description,
      email: formikEmail,
      languageId: formikLanguageId,
      phone,
      mobile,
      address,
      city,
      province,
      postalCode,
    } = values ?? {};

    const cleanedValues = {
      name,
      description,
      email: formikEmail,
      languageId: formikLanguageId,
      phone,
      mobile,
      address,
      city,
      province,
      postalCode,
      pharmacyIds,
    };

    switch (type) {
      case create:
        dispatch(createPharmacyAdmin(cleanedValues));
        break;
      case edit:
        dispatch(
          editPharmacyAdmin({
            ...cleanedValues,
            pharmacyCompanyId: id,
          }),
        );
        break;
      case appoint:
        dispatch(
          appointExistingPharmacy({
            pharmacyCompany: cleanedValues,
            pharmacyId: id,
            pharmacyIds,
          }),
        );
        break;
      default:
        break;
    }
  };

  const api = {
    [edit]: getPharmacyAdminDetails,
    [appoint]: getPharmacyDetails,
  };

  useEffect(() => {
    if (notCreate) {
      dispatch(api[type](id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const selectedList = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      selectedPharmacies(),
    [pharmacies],
  );

  return {
    initialValues: initialValues[type],
    selectedPharmacies: selectedList,
    title: title[type],
    handleSubmit,
    isAppoint,
    data: filteredData(),
    isEdit,
  };
};

export default useHandleEditAppoint;
