import React from 'react';
import { IAllLanguages, IProvinces } from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import {
  Constants,
  TextFieldVariants,
  telephoneInputMask,
} from '../../../../helpers/Constants';
import strings from '../../../../localization';
import genericClasses from '../../../../theme/GenericClasses';
import { ReactComponent as UploadIcon } from '../../../../assets/svg/uploadCloud.svg';
import styles from './styles';

const { text, dropdown, masked, uploadIconLabel } = Constants.formInputType;
const { customFilledInput } = genericClasses;
const { filled } = TextFieldVariants;

export const enterPlaceholder = (suffix: string) =>
  strings.formatString(strings.enterCred, suffix);

export const config = (
  provinces: Array<IProvinces>,
  languages: Array<IAllLanguages>,
  isAppoint: boolean,
  showPictureUploadModal: () => void,
  isEdit: boolean,
  nameOfImage?:string,
) => {
  const formContent = [
    {
      key: 1,
      label: strings.name,
      placeholder: enterPlaceholder(strings.name),
      customTextFieldStyle: customFilledInput,
      variant: filled,
      name: 'name',
      type: text,
      xs: 3,
    },
    {
      key: 2,
      label: strings.description,
      placeholder: enterPlaceholder(strings.description),
      customTextFieldStyle: customFilledInput,
      variant: filled,
      type: text,
      name: 'description',
      xs: 3,
    },
    {
      key: 3,
      label: strings.email,
      placeholder: enterPlaceholder(strings.email),
      customTextFieldStyle: customFilledInput,
      disabled: isAppoint,
      variant: filled,
      name: 'email',
      type: text,
      xs: 3,
    },
    {
      key: 5,
      label: strings.language,
      name: 'languageId',
      type: dropdown,
      menuItems: dropdownMapper(languages, 'languageId', 'name'),
      variant: filled,
      xs: 3,
    },
    {
      key: 6,
      label: strings.phone,
      type: masked,
      mask: telephoneInputMask,
      customTextFieldStyle: customFilledInput,
      variant: filled,
      xs: 3,
      name: 'phone',
    },
    {
      key: 7,
      label: strings.mobile,
      name: 'mobile',
      customTextFieldStyle: customFilledInput,
      variant: filled,
      type: masked,
      mask: telephoneInputMask,
      xs: 3,
    },
    {
      key: 8,
      label: strings.address,
      customTextFieldStyle: customFilledInput,
      variant: filled,
      name: 'address',
      type: text,
      xs: 3,
    },

    {
      key: 9,
      label: strings.city,
      name: 'city',
      customTextFieldStyle: customFilledInput,
      variant: filled,
      type: text,
      xs: 3,
    },
    {
      key: 10,
      label: strings.province,
      type: dropdown,
      xs: 3,
      name: 'province',
      menuItems: dropdownMapper(provinces, 'key', 'name'),
      customMenuStyles: styles.provinceDropdown,
      variant: filled,
    },
    {
      key: 11,
      label: strings.postalCode,
      customTextFieldStyle: customFilledInput,
      variant: filled,
      name: 'postalCode',
      type: text,
      xs: 3,
    },
  ];

  if (isEdit) {
    formContent.push({
      key: 12,
      uploadLabel: nameOfImage,
      label: strings.uploadPicture,
      variant: Constants.variant.outlined,
      type: uploadIconLabel,
      customClass: styles.uploadButton(nameOfImage),
      startIcon: <UploadIcon />,
      xs: 3,
      onClick: showPictureUploadModal,
    } as any);
  }

  const arr = [
    {
      key: 1,
      title: strings.general,
      form: () =>
        formContent,
    },
    {
      key: 2,
      title: strings.myPharmacies,
      form: () =>
        [],
    },
  ];

  return arr;
};
