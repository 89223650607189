import React from 'react';

import { Navigate, Route } from 'react-router-dom';

import {
  DashboardType,
  enableChat,
  getIsUserSignedUp,
  PendingActivationTypes,
  TypeOfUser,
} from '@pharmaplan/common';

import LogoutHandler from '@pharmaplan/common/helpers/LogoutHandler';
import AdminHome from '../components/Admin/Home';
import AdminReports from '../components/Admin/AdminReports';
import AdminDashboard from '../components/Admin/Dashboard';
import PharmacistList from '../components/Admin/PharmacistList';
import PharmacyChains from '../components/Admin/PharmacyChains';
import SoftwareList from '../components/Admin/SoftwareList';
import PharmacyList from '../components/Admin/PharmacyList';
import IncompatibleMatches from '../components/Admin/IncompatibleMatches';
import AdminConfiguration from '../components/Admin/AdminConfiguration';
import ContactPreferences from '../components/Admin/ContactPreferences';
import PendingActivationList from '../components/Admin/PendingActivationList';
import PendingCancellations from '../components/Admin/PendingCancellations';
import PharmacistCapacities from '../components/Admin/PharmacistCapacities';
import UpdateIncompatibleMatches from '../components/Admin/IncompatibleMatches/UpdateIncompatibleMatches';
import AdminList from '../components/Admin/AdminList';
import { useAppSelector } from '../hooks/useAppSelector';
import useAdmin from '../hooks/useAdmin';
import {
  BroadcastGroupActionType,
  BroadcastTabsType,
  Constants,
  PharmacyAdminActionTypes,
} from '../helpers/Constants';
import CreateNewAdmin from '../components/Admin/AdminList/CreateNewAdmin';
import UpdateAdmin from '../components/Admin/AdminList/CreateNewAdmin/UpdateAdmin';
import ListOfWorkshifts from '../components/Admin/PharmacyList/ListOfWorkshifts';
import DetailedRatings from '../components/Admin/DetailedRatings';
import BroadcastList from '../components/Admin/BroadcastList';
import CreateEditBroadcastGroup from '../components/Admin/BroadcastList/CreateEditBroadcastGroup';
import CreateEditBroadcastNotification from '../components/Admin/BroadcastList/CreateEditBroadcastNotification';

import ProtectedRoute from './ProtectedRoute';
import Logs from '../components/Admin/Logs';
import AdminProfile from '../components/Admin/AdminProfile';
import ChangePassword from '../components/ChangePassword';
import PharmacyAdministrators from '../components/Admin/PharmacyAdministrators';
import CreateEditPharmacyAdministrator from '../components/Admin/PharmacyAdministrators/CreateEditPharmacyAdministrator';
import AppointExistingPharmacy from '../components/Admin/PharmacyAdministrators/AppointExistingPharmacyList/AppointExistingPharmacy';
import PharmaplanStatistics from '../components/Admin/PharmaplanStatistics';
import ChatWindow from '../components/Admin/ChatWindow';
import ProtectedCustomRoute from './DashboardProtectedRoute';

const {
  pharmaplanStatistics,
  home,
  adminCalendarDashboard,
  softwareList,
  pharmacistCapacities,
  pharmacistList,
  pharmacyChains,
  dashboard,
  pendingActivationList,
  pendingCancellation,
  administratorList,
  adminConfiguration,
  reports,
  logs,
  contactPreferences,
  pharmacyList,
  incompatibleMatch,
  updateIncompatibleMatch,
  bookingReport,
  requestedReport,
  availabilityReport,
  createNewAdmin,
  updateAdmin,
  postedWorkshiftReport,
  createMultiple,
  listOfWorkshifts,
  viewRatings,
  pharmacyListIncompatible,
  pharmacyListIncompatibleUpdate,
  broadcastList,
  createBroadcastGroup,
  editBroadcastGroup,
  createNotification,
  editNotification,
  activityLogs,
  workshiftLogs,
  bookingLogs,
  pharmacyAdministrators,
  createPharmacyAdministrators,
  updatePharmacyAdministrators,
  appointPharmacyAdministrators,
  availabilityLogs,
  appointPharmacyList,
  broadcastListNotification,
  profile,
  changePassword,
  pendingActivationListPharmacist,
  adminChat,
} = Constants.paths.admin;

const { notifications, groups } = BroadcastTabsType;
const { create, appoint, edit } = PharmacyAdminActionTypes;

const AdminRouter = () => {
  const { isAdmin } = useAdmin();

  const user = !!LogoutHandler.isUserLoggedIn();
  const isUserSignedUp = useAppSelector(getIsUserSignedUp);
  const isChatEnabled = useAppSelector(enableChat);

  const isAuthenticatedUser = user && !isUserSignedUp;

  const isAuthenticatedAdmin = isAdmin && isAuthenticatedUser;
  return (
    <Route
      path={home}
      element={<ProtectedRoute authenticated={isAuthenticatedAdmin} />}
    >
      <Route path={home} element={<AdminHome />}>
        <Route
          path={home}
          element={<Navigate replace to={adminCalendarDashboard} />}
        />
        <Route
          path={dashboard}
          element={<Navigate replace to={adminCalendarDashboard} />}
        />
        <Route path={dashboard} element={<AdminDashboard />}>
          <Route path={DashboardType.calendar} element={<AdminDashboard />} />
          <Route path={DashboardType.map} element={<AdminDashboard />} />
        </Route>
        <Route path={createMultiple} element={<AdminDashboard />} />

        <Route path={softwareList} element={<SoftwareList />} />
        <Route
          path={pendingActivationList}
          element={<Navigate replace to={pendingActivationListPharmacist} />}
        />
        <Route path={pendingActivationList} element={<PendingActivationList />}>
          <Route
            path={TypeOfUser.pharmacist}
            element={<PendingActivationList />}
          />
          <Route
            path={TypeOfUser.pharmacy}
            element={<PendingActivationList />}
          />
          <Route
            path={PendingActivationTypes.deletedUsers}
            element={<PendingActivationList />}
          />
        </Route>
        <Route path={pharmaplanStatistics} element={<PharmaplanStatistics />} />
        <Route path={pendingCancellation} element={<PendingCancellations />} />
        <Route path={pharmacistCapacities} element={<PharmacistCapacities />} />
        <Route path={contactPreferences} element={<ContactPreferences />} />
        <Route path={pharmacyChains} element={<PharmacyChains />} />
        <Route path={administratorList} element={<AdminList />} />
        <Route
          path={reports}
          element={<Navigate replace to={bookingReport} />}
        />
        <Route path={bookingReport} element={<AdminReports />} />
        <Route path={availabilityReport} element={<AdminReports />} />
        <Route path={requestedReport} element={<AdminReports />} />
        <Route path={postedWorkshiftReport} element={<AdminReports />} />
        <Route path={pharmacistList} element={<PharmacistList />} />
        <Route path={pharmacyList} element={<PharmacyList />} />
        <Route path={listOfWorkshifts} element={<ListOfWorkshifts />} />
        <Route path={adminConfiguration} element={<AdminConfiguration />} />
        <Route path={incompatibleMatch} element={<IncompatibleMatches />} />
        <Route path={createNewAdmin} element={<CreateNewAdmin />} />
        <Route path={updateAdmin} element={<UpdateAdmin />} />
        <Route
          path={updateIncompatibleMatch}
          element={<UpdateIncompatibleMatches />}
        />
        <Route path={viewRatings} element={<DetailedRatings />} />
        <Route
          path={pharmacyListIncompatible}
          element={<IncompatibleMatches />}
        />
        <Route
          path={pharmacyListIncompatibleUpdate}
          element={<UpdateIncompatibleMatches />}
        />
        <Route
          path={broadcastList}
          element={<Navigate to={broadcastListNotification} />}
        />
        <Route path={broadcastList} element={<BroadcastList />}>
          <Route path={notifications} element={<BroadcastList />} />
          <Route path={groups} element={<BroadcastList />} />
        </Route>
        <Route
          path={createBroadcastGroup}
          element={
            <CreateEditBroadcastGroup type={BroadcastGroupActionType.create} />
          }
        />
        <Route
          path={editBroadcastGroup}
          element={
            <CreateEditBroadcastGroup type={BroadcastGroupActionType.edit} />
          }
        />
        <Route
          path={createNotification}
          element={(
            <CreateEditBroadcastNotification
              type={BroadcastGroupActionType.create}
            />
          )}
        />
        <Route
          path={editNotification}
          element={(
            <CreateEditBroadcastNotification
              type={BroadcastGroupActionType.edit}
            />
          )}
        />
        <Route path={logs} element={<Navigate replace to={activityLogs} />} />
        <Route path={activityLogs} element={<Logs />} />
        <Route path={workshiftLogs} element={<Logs />} />
        <Route path={availabilityLogs} element={<Logs />} />
        <Route path={bookingLogs} element={<Logs />} />
        <Route path={profile} element={<AdminProfile />} />
        <Route path={changePassword} element={<ChangePassword />} />
        <Route
          path={pharmacyAdministrators}
          element={<PharmacyAdministrators />}
        />
        <Route
          path={createPharmacyAdministrators}
          element={<CreateEditPharmacyAdministrator type={create} />}
        />
        <Route
          path={updatePharmacyAdministrators}
          element={<CreateEditPharmacyAdministrator type={edit} />}
        />
        <Route
          path={appointPharmacyAdministrators}
          element={<CreateEditPharmacyAdministrator type={appoint} />}
        />
        <Route
          path={appointPharmacyList}
          element={<AppointExistingPharmacy />}
        />
        <Route
          path={adminChat}
          element={(
            <ProtectedCustomRoute
              route={Constants.paths.admin.adminCalendarDashboard}
              allow={isChatEnabled}
            />
          )}
        >
          <Route path={adminChat} element={<ChatWindow />} />
        </Route>
      </Route>
    </Route>
  );
};

export default AdminRouter;
