import React, { FC, useEffect, useState } from 'react';

import { EditorContent, useEditor } from '@tiptap/react';
import ImageResize from 'tiptap-extension-resize-image';
import ImageExt from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import { FormikProps } from 'formik';
import { Box } from '@mui/system';

import useEditorUtility from '../../../hooks/Editor/useEditorUtility';

import EditorToolbar from './EditorToolbar';
import styles from './styles';
import './style.css';

const extensions = [StarterKit, ImageExt, ImageResize];

interface IEmailEditor {
  formik: FormikProps<any>;
  name: string;
}

const EmailEditor: FC<IEmailEditor> = ({ formik, name }) => {
  const { handleDrop } = useEditorUtility();
  const [focus, setFocus] = useState(false);
  const editor = useEditor({
    extensions,
    content: formik.values[name],
    editorProps: {
      handleDrop,
    },
  });

  useEffect(() => {
    formik.setFieldValue(name, editor?.getHTML());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor?.getHTML()]);
  // eslint-disable-next-line no-unsafe-optional-chaining

  const handleFocus = (focused: boolean) =>
    () => {
      setFocus(focused);
    };

  return (
    <Box sx={[styles.active, focus && styles.focusedStyle]}>
      <EditorToolbar editor={editor} />
      <EditorContent
        style={styles.editor}
        editor={editor}
        rows={5}
        height={200}
        onFocus={handleFocus(true)}
        onBlur={handleFocus(false)}
      />
    </Box>
  );
};

export default EmailEditor;
