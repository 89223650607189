import React, { useEffect } from 'react';

import { Grid, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

import {
  DashboardType,
  calendarDate,
  getActiveDashboard,
  getActiveUsers,
  getAdminCalendar,
  selectActiveUsersSegment,
  setActiveDashboard,
  getAllPharmacistAvailabilies,
  adminActions,
  resetStatus,
  getAdminCalendarFilters,
  successOrSelector,
} from '@pharmaplan/common';

import Toolbar from '../../Dashboard/Toolbar';
import strings from '../../../localization';
import ContentContainer from '../../common/ContentContainer';
import LegendContainer from '../../common/LegendContainer';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getISO, mapFormat, setPageTitle } from '../../../helpers/Functions';

import ActiveUsers from '../ActiveUsers';
import theme from '../../../theme';
import { BreakPoints, todaysDateISO } from '../../../helpers/Constants';
import ResponsiveClasses from '../../../theme/ResponsiveClasses';
import { DashboardComponents } from '../../Dashboard/Dashboard';
import useAdminRefresh from '../../../hooks/Admin/useAdminRefresh';
import useInterval from '../../../hooks/useInterval';
import useAdmin from '../../../hooks/useAdmin';

import { getMultipleEventAdd } from '../../../selectors/calendarSelector';
import useAdminContinueCreation from '../../../hooks/Admin/useAdminContinueCreation';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import { showSuccess } from '../Profile/Pharmacist/PharmacistViewProfile/helper';

const { deletePharmacistAvailabilities, deleteAdminPharmacistVacations } = adminActions;
const { lg } = BreakPoints;

const intervalDelay = 5 * 60 * 1000;

const deleteActions = [
  deleteAdminPharmacistVacations,
  deletePharmacistAvailabilities,
];

const AdminDashboard = () => {
  const dispatch = useAppDispatch();
  const { isAdmin } = useAdmin();

  const { state } = useLocation();
  const deleteSuccess = useAppSelector((st) =>
    successOrSelector(deleteActions, st));

  const { id } = state ?? {};

  useAdminRefresh();
  useAdminContinueCreation();
  useAdminPermissions();

  const { pathname } = useLocation();

  const lessThanLg = useMediaQuery(theme.breakpoints.down(lg));

  const cDate = useAppSelector(calendarDate);
  const activeDash = useAppSelector(getActiveDashboard);
  const isMultipleAdd = useAppSelector(getMultipleEventAdd);
  const segment = useAppSelector(selectActiveUsersSegment);

  const isMultipleSelect = isAdmin && id && isMultipleAdd;
  const splitPath = pathname.split('/')[3];

  const getCalendar = () => {
    const startDate = mapFormat(getISO(cDate.startDate));
    const endDate = mapFormat(getISO(cDate.endDate));
    isMultipleSelect
      ? dispatch(
        getAllPharmacistAvailabilies({
          pharmacistId: id,
          startDate: mapFormat(todaysDateISO() ?? ''),
          endDate,
        }),
      )
      : dispatch(
        getAdminCalendar({
          startDate,
          endDate,
        }),
      );
  };

  const intervalCallback = () => {
    if (!isMultipleSelect) {
      getCalendar();
      dispatch(getActiveUsers({ page: 1, user: segment }));
    }
  };

  useEffect(() => {
    getCalendar();
    dispatch(getAdminCalendarFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cDate]);

  useEffect(() => {
    setPageTitle(strings.dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setActiveDashboard(splitPath as DashboardType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (deleteSuccess) {
      showSuccess(dispatch, strings.deletedSuccessfully);
      dispatch(resetStatus(deleteActions));
      getCalendar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  useInterval(intervalCallback, intervalDelay, [cDate]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={10}>
        <ContentContainer>
          {lessThanLg && <LegendContainer row />}
          <Toolbar
            title={
              isMultipleSelect
                ? strings.createMultipleFullDay
                : strings.adminDashboard
            }
          />
          {DashboardComponents[activeDash]}
        </ContentContainer>
      </Grid>
      <Grid xs={2} item>
        <Grid item sx={ResponsiveClasses.hideOnLg}>
          <LegendContainer />
        </Grid>
        <ActiveUsers />
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
