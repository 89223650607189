import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from '../controllers/httpClient';
import { IDeviceDetails } from '../controllers/MobileRegController';
import { ICreateProfileParams } from '../controllers/types';
import UserController from '../controllers/UserController';
import {
  ColorScheme,
  PlatformTypes,
  SocialLoginType,
  TypeOfUser,
} from '../helpers/Constants';
import {
  IPharmacyInfoParams,
  IUserPropsModel,
  ISmartParamLoginParams,
} from '../models';
import { AppIDType } from '../models/IAppID';
import {
  IChangePasswordParams,
  IContact,
  IDeviceModel,
  IGeneral,
  IProfessionalInfo,
  ISignUpParams,
  ISoftware,
} from '../models/PharmacistModels';
import { statusDispatch } from './globalActions';

const user = 'user/';
export const userActions = {
  createProfile: `${user}CREATE_PROFILE`,
  signup: `${user}SIGNUP`,
  login: `${user}LOGIN`,
  profile: `${user}PROFILE`,
  general: `${user}GENERAL`,
  generalUpdate: `${user}GENERAL_UPDATE`,
  professionalInformation: 'PROFESSIONALINFORMATION',
  professionalInformation_update: 'PROFESSIONALINFORMATION_UPDATE',
  contact: `${user}CONTACT`,
  contactUpdate: `${user}CONTACT_UPDATE`,
  software: `${user}SOFTWARE`,
  softwareUpdate: `${user}SOFTWARE_UPDATE`,
  profileUtils: `${user}PROFILE_UTILS`,
  logos: `${user}LOGOS`,
  logoById: `${user}LogoById`,
  logout: `${user}logout`,
  setLanguage: `set${user}language`,
  setPreLoginLangWeb: `${user}preLoginLang`,
  changePassword: `${user}changePassword`,
  forgotPassword: `${user}forgotPassword`,
  resetPassword: `${user}resetPassword`,
  setLocale: `${user}locale`,
  pharmacyInfo: `${user}pharmacy/info`,
  primaryContact: `${user}pharmacy/primaryContact`,
  secondarycontact: `${user}pharmacy/secondarycontact`,
  accountingcontact: `${user}pharmacy/accountingcontact`,
  coordinates: `${user}pharmacy/coordinates`,
  coordinatesUpdate: `${user}pharmacy/updateCoordinates`,
  updateCommonContact: `${user}pharmacy/commonContact`,
  commonContact: `${user}pharmacy/commonContact`,
  updatePharmacyInfo: `${user}pharmacy/info/update`,
  requestReset: `${user}request/reset`,
  resendOtp: `${user}otp/resend`,
  verifyOtp: `${user}otp/verify`,
  getFaq: `${user}faq`,
  getUsername: `${user}get/name`,
  resetName: `${user}reset/name`,
  getTermsAndConditions: `${user}termsAndConditions`,
  isTermsAgreed: `${user}termsAgreedStatus`,
  setLocation: `${user}set/location`,
  setAddress: `${user}set/address`,
  resetUpdateOperations: `${user}updateOperations/reset`,
  resetValidOtp: `${user}otp/valid/reset`,
  resetSignedUp: `${user}signedUp/reset`,
  resetApproval: `${user}approval/reset`,
  setUserProps: `${user}set/props`,
  doesEmailExist: `${user}email/exists`,
  setSignedUp: `${user}set/SignedUp`,
  smartLogin: `${user}smart/login`,
  setStartTour: `${user}set/tour`,
  deleteUser: `${user}delete`,
  getUserTheme: `${user}get/theme`,
  setUserTheme: `${user}set/theme`,
  getUserConfigurations: `${user}get/configurations`,
  sendOtp: `${user}send/otp`,
  authResendOtp: `${user}resend/otp`,
  authVerifyOtp: `${user}verify-otp`,
  addEmailPassword: `${user}/addEmailPassword`,
  resetDidRequestEmailReset: `${user}/reset/request-email`,
  resetLoggedIn: `${user}/reset/loggedIn`,
  setUserType: `${user}/set/userType`,
  setIsPharmacyAdminLogin: `${user}/set/isPharmacyAdminLogin`,
  resetProfileImage: `${user}/reset/profileImage`,
};

export const logout = createAsyncThunk(
  userActions.logout,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.logout();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const login = createAsyncThunk(
  userActions.login,
  async (
    {
      email,
      token,
      loginType,
      password,
      appId,
      rememberMe,
      device,
    }: {
      email: string | null;
      password?: string;
      appId: AppIDType;
      loginType?: SocialLoginType;
      token?: string;
      rememberMe?: boolean;
      device?: IDeviceModel;
    },
    { rejectWithValue }
  ) => {
    try {
      const resp: any = await UserController.login(
        email,
        appId,
        device,
        token,
        loginType,
        password,
        rememberMe
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createProfile = createAsyncThunk(
  userActions.createProfile,
  async (
    _params: { params: ICreateProfileParams; user: TypeOfUser },
    { rejectWithValue }
  ) => {
    try {
      const resp = await UserController.createProfile(_params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateUserContact = createAsyncThunk(
  userActions.contactUpdate,
  async (values: IContact, { rejectWithValue }) => {
    try {
      const resp = await UserController.contactUpdate(values);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const coordinatesUpdate = createAsyncThunk(
  userActions.coordinatesUpdate,
  async (values: any, { rejectWithValue }) => {
    try {
      const resp = await UserController.coordinateUpdate(values);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserContact = createAsyncThunk(
  userActions.contact,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.contact();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserGeneral = createAsyncThunk(
  userActions.general,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.general();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateUserGeneral = createAsyncThunk(
  userActions.generalUpdate,
  async (values: IGeneral, { rejectWithValue }) => {
    try {
      const resp = await UserController.generalUpdate(values);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProInfo = createAsyncThunk(
  userActions.professionalInformation,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.proInfo();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateProInfo = createAsyncThunk(
  userActions.professionalInformation_update,
  async (values: IProfessionalInfo, { rejectWithValue }) => {
    try {
      const resp = await UserController.proInfoUpdate(values);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  userActions.profile,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.profile();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getPharmacyInfo = createAsyncThunk(
  userActions.pharmacyInfo,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getPharmacyInfo();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updatePharmacyInfo = createAsyncThunk(
  userActions.updatePharmacyInfo,
  async (params: IPharmacyInfoParams, { rejectWithValue }) => {
    try {
      const resp = await UserController.updatePharmacyInfo(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserPrimaryContact = createAsyncThunk(
  userActions.primaryContact,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getPrimaryContact();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUserSecondaryContact = createAsyncThunk(
  userActions.profile,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getSecondaryContact();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePharmactContact = createAsyncThunk(
  userActions.updateCommonContact,
  async (_params: any, { rejectWithValue }) => {
    try {
      const resp = await UserController.pharmacyContactUpdate(_params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserAccountingContact = createAsyncThunk(
  userActions.accountingcontact,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getAccountingContact();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUserCoordinates = createAsyncThunk(
  userActions.coordinates,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getCoordinates();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSoftware = createAsyncThunk(
  userActions.software,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.software();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateSoftware = createAsyncThunk(
  userActions.softwareUpdate,
  async (_params: Array<Omit<ISoftware, 'name'>>, { rejectWithValue }) => {
    try {
      const resp = await UserController.softwareUpdate(_params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllBannerLogos = createAsyncThunk(
  userActions.logos,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getAllBannerLogos();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLogoById = createAsyncThunk(
  userActions.logoById,
  async ({ logoId }: { logoId: string }, { rejectWithValue }) => {
    try {
      const resp = await UserController.getBannerLogoByLogoID(logoId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const setUserLanguage = createAction<string>(userActions.setLanguage);
export const setPreLoginLangWeb = createAction<string>(
  userActions.setPreLoginLangWeb
);
export const getChangePassword = createAsyncThunk(
  userActions.changePassword,
  async (params: IChangePasswordParams, { rejectWithValue }) => {
    try {
      const resp = await UserController.changePassword(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  userActions.forgotPassword,
  async (email: string, { rejectWithValue }) => {
    try {
      const resp = await UserController.forgotPassword(email);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  userActions.resetPassword,
  async (params: { password: string; email: string }, { rejectWithValue }) => {
    try {
      const resp = await UserController.resetPassword(
        params.email,
        params.password
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resendOtp = createAsyncThunk(
  userActions.resendOtp,
  async (email: string, { rejectWithValue }) => {
    try {
      const resp = await UserController.resendOtp(email);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  userActions.verifyOtp,
  async (params: { email: string; otp: string }, { rejectWithValue }) => {
    try {
      const resp = await UserController.verifyOtp(params.email, params.otp);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const profileUtils = createAsyncThunk(
  userActions.profileUtils,
  async (userType: TypeOfUser, { rejectWithValue }) => {
    try {
      const resp = await UserController.profileUtils(userType);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFaq = createAsyncThunk(
  userActions.getFaq,
  async (platformType: PlatformTypes, { rejectWithValue }) => {
    try {
      const resp = await UserController.getPlatformFaq(platformType);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsername = createAsyncThunk(
  userActions.getUsername,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getUserName();
      return (resp as any)?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTermsAndConditions = createAsyncThunk(
  userActions.getTermsAndConditions,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getTermsAndConditions();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const doesEmailExist = createAsyncThunk(
  userActions.doesEmailExist,
  async (email: string, { rejectWithValue }) => {
    try {
      const resp = await UserController.doesEmailExist(email);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const smartLogin = createAsyncThunk(
  userActions.smartLogin,
  async (params: ISmartParamLoginParams, { rejectWithValue }) => {
    try {
      const resp = await UserController.smartLogin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteUserAccount = createAsyncThunk(
  userActions.deleteUser,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.deleteUser();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserPreferredTheme = createAsyncThunk(
  userActions.getUserTheme,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.getTheme();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setUserPreferredTheme = createAsyncThunk(
  userActions.setUserTheme,
  async (theme: ColorScheme, { rejectWithValue }) => {
    try {
      const resp = await UserController.setTheme(theme);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserConfigurations = createAsyncThunk(
  userActions.getUserConfigurations,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserController.userConfigurations();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendOtp = createAsyncThunk(
  userActions.sendOtp,
  async (email: string, { rejectWithValue }) => {
    try {
      const resp = await UserController.sendOtp(email);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authResendOtp = createAsyncThunk(
  userActions.authResendOtp,
  async (email: string, { rejectWithValue }) => {
    try {
      const resp = await UserController.authResendOtp(email);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authVerifyOtp = createAsyncThunk(
  userActions.authVerifyOtp,
  async (params: { email: string; otp: string }, { rejectWithValue }) => {
    try {
      const resp = await UserController.authVerifyOtp(params.email, params.otp);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addEmailPassword = createAsyncThunk(
  userActions.addEmailPassword,
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await UserController.addEmailPassword(email, password);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const setRequestReset = createAction<boolean>(userActions.requestReset);
export const setEmailChangeReset = createAction<boolean>(
  userActions.resetDidRequestEmailReset
);
export const resetName = createAction(userActions.resetName);
export const setLocation = createAction<{
  latitude: number | null;
  longitude: number | null;
}>(userActions.setLocation);
export const setAddress = createAction<string>(userActions.setAddress);
export const istermsAgreed = createAction<boolean>(userActions.isTermsAgreed);
export const resetValidOtp = createAction(userActions.resetValidOtp);
export const resetUpdateOperations = createAction(
  userActions.resetUpdateOperations
);
export const resetSignedUp = createAction(userActions.resetSignedUp);
export const resetApproval = createAction(userActions.resetApproval);
export const setUserProps = createAction<IUserPropsModel>(
  userActions.setUserProps
);

export const setSignedUp = createAction<boolean>(userActions.setSignedUp);
export const setStartTour = createAction<boolean>(userActions.setStartTour);
export const resetLoggedIn = createAction(userActions.resetLoggedIn);
export const setUserType = createAction<TypeOfUser>(userActions.setUserType);
export const resetProfileImage = createAction(userActions.resetProfileImage);
