import React, { FC } from 'react';

import { Grid } from '@mui/material';
import { FormikProps } from 'formik';

import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import { logos, publishedSoftwareList } from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import FilterSubmitButtons from '../FilterSubmitButtons';
import CustomTextField from '../CustomTextField';
import CustomDropdown from '../CustomDropdown';
import strings from '../../../localization';

import styles from './styles';

interface IAdminPharmacySearchFields {
  formik: FormikProps<any>;
  handleReset: () => void;
}

const AdminPharmacySearchFields: FC<IAdminPharmacySearchFields> = ({
  formik,
  handleReset,
}) => {
  const softwareList = useAppSelector(publishedSoftwareList);
  const allLogos = useAppSelector(logos);

  return (
    <Grid container lg={10} alignItems="center" xs={12} wrap="nowrap">
      <Grid xs={6}>
        <CustomDropdown
          formik={formik}
          label={strings.banners}
          name="banners"
          id="banners"
          imageMenu
          multiple
          customMenuStyles={styles.bannerDropdown}
          menuItems={dropdownMapper(
            allLogos,
            'pharmacyChainId',
            'name',
          )}
        />
      </Grid>
      <Grid xs={6} sx={styles.codeContainer}>
        <CustomTextField
          formik={formik}
          name="userText"
          placeholder={strings.formatString(
            strings.enterCred,
            `${strings.name}/${strings.email}`,
          )}
          id="userText"
          label={strings.pharmacy}
        />
      </Grid>
      <Grid xs={6} sx={styles.codeContainer}>
        <CustomTextField
          formik={formik}
          name="contactName"
          placeholder={strings.formatString(
            strings.enterCred,
            strings.contactName,
          )}
          id="contactName"
          label={strings.contactName}
        />
      </Grid>
      <Grid xs={6} sx={styles.codeContainer}>
        <CustomDropdown
          formik={formik}
          label={strings.software}
          name="softwareId"
          id="softwareId"
          menuItems={dropdownMapper(softwareList, 'softwareId', 'name')}
        />
      </Grid>
      <Grid xs={6} sx={styles.codeContainer}>
        <CustomTextField
          formik={formik}
          name="city"
          placeholder={strings.formatString(strings.enterCred, strings.city)}
          id="city"
          label={strings.city}
        />
      </Grid>

      <FilterSubmitButtons handleReset={handleReset} />
    </Grid>
  );
};

export default AdminPharmacySearchFields;
