const legendHeight = 283;

const classes = {
  adsContainer: {
    padding: '5px',
    textAlign: 'center',
    minHeight: 308,
  },

  adsContainerImg: {
    maxWidth: '100%',
    height: 'inherit',
    minHeight: 308,

  },

  dynamicHeight: (height: number) =>
    ({
      height: (height * 3) / 4 - legendHeight,
    }),
};

export default classes;
