import React, { FC } from 'react';

import { Box, IconButton } from '@mui/material';
import { FormikProps } from 'formik';

import { Cancel } from '@mui/icons-material';
import strings from '../../../localization';
import { ButtonSize } from '../../../helpers/Constants';
import { ReactComponent as UploadIcon } from '../../../assets/svg/uploadCloud.svg';
import { ReactComponent as DisabledUploadIcon } from '../../../assets/svg/disabledUploadIcon.svg';

import CustomButton from '../CustomButton';
import styles from './style';
import UploadButton from '../UploadButton';

interface IUploadDialog {
  errorMessage: string;
  message: string;
  acceptedFileTypes: string;
  fileSize: number;
  chooseLabel: string;
  customComponent: React.ReactNode;
  formik: FormikProps<any>;
  name: string;
  handleClear?: () => void;
  handleOpen?: () => void;
  disableButton?: boolean;
}

const UploadDialog: FC<IUploadDialog> = ({
  formik,
  name,
  message,
  errorMessage,
  acceptedFileTypes,
  chooseLabel,
  fileSize,
  customComponent,
  disableButton,
  handleClear,
  handleOpen,
}) => {
  const fileName = formik?.values[name]?.name;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        {customComponent}
        <Box sx={styles.mainContainer}>
          {!!fileName && (
            <Box sx={styles.fileContainer}>
              <Box component="span" onClick={handleOpen} sx={styles.imageName}>
                {fileName}
              </Box>
              <IconButton onClick={handleClear} sx={styles.iconButton}>
                <Cancel sx={styles.crossIcon} />
              </IconButton>
            </Box>
          )}
          <UploadButton
            name={name}
            chooseLabel={chooseLabel}
            startIcon={fileName ? <DisabledUploadIcon /> : <UploadIcon />}
            message={message}
            errorMessage={errorMessage}
            acceptedFileTypes={acceptedFileTypes}
            fileSize={fileSize}
            disabled={!!fileName}
            formik={formik}
          />
        </Box>
      </Box>

      <Box sx={styles.submitButtonContainer}>
        <CustomButton
          label={strings.submit}
          customClass={styles.buttonWidth}
          size={ButtonSize.small}
          onClick={formik.handleSubmit}
          disabled={disableButton}
        />
      </Box>
    </Box>
  );
};
export default UploadDialog;
