import React, { useEffect, useState } from 'react';

import {
  adminActions,
  getOwnersList,
  getPendingActivationsCount,
  HelpTypes,
  isLoadingSelector,
  MiscType,
  pendingActivationList,
  PendingActivationTypes,
  PendingTabTypes,
  PermissionsOfAdmin,
  TypeOfUser,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';

import DynamicTable from '../../DynamicTable';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { renderScreen } from '../../../actions/drawerActions';
import {
  MomentTimeFormats,
  OtherScreens,
  ScreenTypes,
  SecondaryAdminNavigation,
} from '../../../helpers/Constants';
import usePendingActivations from '../../../hooks/PendingActivations/usePendingActivations';

import {
  deletedUsersRowConfig,
  deleteHeaders,
  pharmacistHeader,
  pharmacistRowConfig,
  pharmacyHeader,
  pharmacyRowConfig,
} from './helper';
import PendingHeader from './PendingHeader';
import usePendingActivationRefresh from '../../../hooks/PendingActivations/usePendingActivationRefresh';
import useDeletePendingActivations from '../../../hooks/PendingActivations/useDeletePendingActivation';
import useDrawer from '../../../hooks/useDrawer';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import useGetItems from '../../../hooks/useGetItems';
import useUserNotFound from '../../../hooks/useUserNotFound';
import { parseUrlType } from '../../../helpers/Functions';
import useTableSort from '../../../hooks/useTableSort';

export const pendingActivationInitialValues = {
  startDate: null,
  endDate: null,
  userType: MiscType.All,
  userKey: '',
  ownerId: '',
};

const { getPendingActivations, getDeletedUsers } = adminActions;
const actions = [getPendingActivations, getDeletedUsers];

const pathToHelpMap = {
  [TypeOfUser.pharmacist.toLowerCase()]: HelpTypes.pharmacist,
  [TypeOfUser.pharmacy.toLowerCase()]: HelpTypes.pharmacy,
  deletedUsers: PendingActivationTypes.deletedUsers,
};

const helpToPathMap = {
  [HelpTypes.pharmacist]: TypeOfUser.pharmacist.toLowerCase(),
  [HelpTypes.pharmacy]: TypeOfUser.pharmacy.toLowerCase(),
  deletedUsers: PendingActivationTypes.deletedUsers,
};

interface IHandleAPI {
  sPage: number;
  startDate: null | DateTime;
  endDate: null | DateTime;
  userType: HelpTypes | null | MiscType;
  userKey: null | string;
  ownerId: string;
  sortBy?: string;
  desc?: boolean;
}

const PendingActivationList = () => {
  const dispatch = useAppDispatch();

  const { getImage } = useGetItems();
  const { isDesc, orderBy, handleSorting, order } = useTableSort();

  const [prefix, selectedTabs] = parseUrlType(
    window.location.pathname,
    [
      TypeOfUser.pharmacist.toLowerCase(),
      TypeOfUser.pharmacy.toLowerCase(),
      PendingActivationTypes.deletedUsers,
    ],
    3,
    TypeOfUser.pharmacist.toLowerCase(),
  );

  const selectedTab = pathToHelpMap[selectedTabs as keyof typeof pathToHelpMap];

  const handleTabClick = (tab: PendingTabTypes) => {
    window.history.pushState(
      {},
      '',
      `${prefix}/${helpToPathMap[tab as keyof typeof helpToPathMap]}`,
    );
  };

  const [page, setPage] = useState(1);
  const { openDrawer } = useDrawer();

  const { can } = useAdminPermissions();
  const canActivate = can(PermissionsOfAdmin.Activation);
  const canPermanentlyDelete = can(PermissionsOfAdmin.PermanentDelete);

  const loadingSuccess = useAppSelector((state) =>
    isLoadingSelector(actions, state));
  const pendingActivations = useAppSelector(pendingActivationList);
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const { showUserNotFound } = useUserNotFound(
    !loadingSuccess,
    !!pendingActivations?.data?.length,
  );

  usePendingActivationRefresh(selectedTab);
  const {
    pendingActivationsAPI,
    pendingUserTypes,
    pendingActivationsDefault,
    confirmAction,
  } = usePendingActivations(selectedTab);

  const {
    handleDelete,
    selectAllFunc,
    handlePress,
    selectedIds,
    resetSelected,
    selectAll,
  } = useDeletePendingActivations(setPage, page);

  const handleAPI = ({
    sPage,
    startDate,
    endDate,
    userType,
    userKey,
    ownerId,
    sortBy,
    desc,
  }: IHandleAPI) => {
    dispatch(
      pendingActivationsAPI()({
        page: sPage,
        userType: pendingUserTypes(userType),
        startDate: startDate
          ? startDate.toFormat(MomentTimeFormats.fullDate)
          : null,
        endDate: endDate ? endDate.toFormat(MomentTimeFormats.fullDate) : null,
        userKey,
        ownerId,
        sortBy,
        desc,
      }),
    );
  };

  const handleSubmit = (values: {
    [x: string]: string | null;
    startDate: null;
    endDate: null;
    userType: HelpTypes | null | MiscType;
    ownerId: string;
  }) => {
    const { endDate, startDate, userKey, userType, ownerId } = values ?? [];
    setPage(1);
    resetSelected();
    handleAPI({ sPage: 1, userType, startDate, endDate, userKey, ownerId });
  };

  const formik = useFormik({
    initialValues: pendingActivationInitialValues,
    onSubmit: handleSubmit,
  });

  const tabUserType = pendingUserTypes(formik.values.userType);

  const { endDate, startDate, userKey, userType, ownerId } = formik.values ?? [];

  const handlePagination = (_: unknown, selectedPage: number) => {
    setPage(selectedPage + 1);
    handleAPI({
      sPage: selectedPage + 1,
      startDate,
      endDate,
      userType,
      userKey,
      ownerId,
    });
  };

  useEffect(() => {
    setPage(1);
    formik.resetForm();
    pendingActivationsDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    dispatch(getPendingActivationsCount());
    dispatch(getOwnersList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      handleAPI({
        sPage: 1,
        startDate,
        endDate,
        userType,
        userKey,
        ownerId,
        sortBy: orderBy,
        desc: isDesc,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  const goToUserProfile = (
    personnelId: string,
    screenNumber: number,
    hasOwner: boolean,
    enableActions: boolean,
  ) => {
    openDrawer();
    dispatch(
      renderScreen({
        screenType: ScreenTypes.profile,
        screenNumber,
        pharmacistId: personnelId,
        pharmacyId: personnelId,
        hasOwner,
        fromScreen:
          selectedTab === PendingActivationTypes.deletedUsers
            ? OtherScreens.DeletedPendingUsers
            : SecondaryAdminNavigation.pendingActivation,
        extraParameters: {
          enableActions,
        },
      }),
    );
  };

  const rowConfig = {
    [HelpTypes.pharmacist]: pharmacistRowConfig,
    [HelpTypes.pharmacy]: pharmacyRowConfig,
    [PendingActivationTypes.deletedUsers]: () =>
      deletedUsersRowConfig(
        pendingActivations.data,
        timeFormat,
        goToUserProfile,
        handlePress,
        selectedIds,
        canPermanentlyDelete,
      ),
  };

  const headerConfig = {
    [HelpTypes.pharmacist]: pharmacistHeader,
    [HelpTypes.pharmacy]: pharmacyHeader,
    [PendingActivationTypes.deletedUsers]: () =>
      deleteHeaders(can),
  };

  const table = {
    title: '',
    headerBar: [],
    headers:
      headerConfig[selectedTab as keyof typeof headerConfig](canActivate),
    rows: rowConfig[selectedTab as keyof typeof rowConfig](
      pendingActivations.data,
      timeFormat,
      goToUserProfile,
      confirmAction(tabUserType),
      canActivate,
      getImage,
    ),
  };

  return (
    <DynamicTable
      table={table}
      loadSuccess={!loadingSuccess}
      page={page}
      order={order}
      orderBy={orderBy}
      handleSort={handleSorting}
      totalCount={pendingActivations.totalCount}
      handlePagination={handlePagination}
      emptyContainerComponent={showUserNotFound()}
      customHeader={(
        <PendingHeader
          resetSelected={resetSelected}
          formik={formik}
          selectedTab={selectedTab}
          setSelected={handleTabClick}
          selectAll={selectAll}
          handleSelectAll={selectAllFunc}
          handleDelete={handleDelete}
        />
      )}
      showHeader={false}
    />
  );
};

export default PendingActivationList;
