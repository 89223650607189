import { IStore } from "../types/IStore";

export const pharmacyReplacements = (state: IStore) => state.pharmacy.replacements;
export const pharmacyReports = (state: IStore) => state.pharmacy.reports;
export const pharmacyHistory = (state: IStore) => state.pharmacy.history;
export const pharmacyNotes = (state: IStore) => state.pharmacy.notes;
export const pharmacyCalendar = (state: IStore) => state.pharmacy.calendar;
export const pharmacyWorkshift = (state: IStore) => state.pharmacy.workshift;
export const pharmacyFilterData = (state: IStore) => state.pharmacy.filterData;
export const pharmacyPdf = (state: IStore) => state.pharmacy.pdf;
export const pharmacistBookingDetails = (state: IStore) => state.pharmacy.pharmacistDetails;
export const bookingsRequested = (state:IStore) => state.pharmacy.bookingsRequested;
export const pharmacyHourlyRate = (state:IStore) => state.pharmacy.pharmacyHourlyRate;
export const ratingItems = (state: IStore) => state.pharmacy.ratingItems;
export const rating = (state: IStore) => state.pharmacy.rating;
export const pharmacyReportIssueData = (state: IStore) => state.pharmacy.reportIssueData;
export const pharmacyReportIssueResponse = (state: IStore) => state.pharmacy.reportIssueResponse;
export const bookedRating = (state: IStore) => state.pharmacy.bookedRating;
export const pharmacyReplacementsWeb = (state: IStore) => state.pharmacy.replacements;
export const pharmacyExists = (state: IStore) => state.pharmacy.pharmacyExists;
export const pharmacyCancelMessage = (state: IStore) => state.pharmacy.cancelBookingMessage;
export const selectPharmacyPreApprove = (state: IStore) => state.pharmacy.preApprove;
export const pharmacistReplacementDetails = (state: IStore) => state.pharmacy.replacementPharmacistDetails;