import React from 'react';
import {
  adminApprove,
  adminDisapprove,
  getPharmacyDetails,
  PermissionsOfAdmin,
  PermissionsRemovalKeys,
} from '@pharmaplan/common';
import { FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../useAppDispatch';
import strings from '../../../localization';

import { renderScreen } from '../../../actions/drawerActions';
import {
  AdminNotesType,
  Constants,
  DeactivateModalIcon,
  OtherScreens,
  ScreenTypes,
  SecondaryAdminNavigation,
} from '../../../helpers/Constants';
import useDrawer from '../../useDrawer';
import { setDialog } from '../../../reducers/dialogReducer';
import DeactivateDialog from '../../../components/Admin/PharmacistList/DeactivateDialog';
import useAdminPermissions from '../useAdminPermissions';

export interface IPharmacyListActionsParams {
  pharmacyId: string;
  name: string;
  email?: string;
}

const { Edit, Deactivate } = PermissionsRemovalKeys;
export const AppointedKeysToRemove = {
  ViewPharmacyAdmins: '4',
  Deactivate,
};

const usePharmacyListActions = (isAppointed: boolean) => {
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();
  const navigate = useNavigate();
  const { can } = useAdminPermissions();

  const cannotActivate = !can(PermissionsOfAdmin.Activation);
  const cannotPost = !can(PermissionsOfAdmin.Posting);
  const cannotChangeParameters = !can(PermissionsOfAdmin.ChangeParameters);

  const showCreateWorkshift = (pharmacyId: string, name: string) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.pharmacyList,
          pharmacyId,
          extraParameters: {
            name,
          },
        }),
      );
    };

  const showEditPharmacy = (pharmacyId: string) =>
    () => {
      dispatch(getPharmacyDetails(pharmacyId));
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 3,
          screenType: ScreenTypes.profile,
          fromScreen: isAppointed
            ? OtherScreens.AppointedPharmacies
            : SecondaryAdminNavigation.pharmacyList,
        }),
      );
    };

  const showPharmacyProfile = (pharmacyId: string) => {
    openDrawer();
    dispatch(
      renderScreen({
        screenType: ScreenTypes.profile,
        screenNumber: 1,
        pharmacyId,
      }),
    );
  };

  const showListOfPharmacists = (pharmacyId: string) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 2,
          screenType: ScreenTypes.pharmacyList,
          pharmacyId,
        }),
      );
    };

  const showListOfWorkshifts = (pharmacyId: string) =>
    () => {
      navigate(Constants.paths.admin.listOfWorkshifts, {
        state: pharmacyId,
      });
    };

  const showIncompatibleMatch = (pharmacyId: string, pharmacyEmail: string) =>
    () => {
      navigate(Constants.paths.admin.pharmacyListIncompatible, {
        state: { pharmacyId, pharmacyEmail },
      });
    };

  const deactivatePharmacy = (values: FormikValues) => {
    const { id, reason } = values ?? {};

    dispatch(adminDisapprove({ id, reason }));
  };

  const activatePharmacy = (values: FormikValues) => {
    const { id, reason } = values ?? {};

    dispatch(adminApprove({ id, reason }));
  };

  const deactivateModal = (pharmacyId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              id={pharmacyId}
              iconType={DeactivateModalIcon.warning}
              confirmationMessage={strings.areYouSureDeactivatePharmacy}
              initialValues={{
                id: pharmacyId,
                reason: '',
              }}
              heading={
              strings.formatString(
                strings.deactivateFormat,
                strings.pharmacy,
              ) as string
            }
              onSubmit={deactivatePharmacy}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: true,
        }),
      );
    };

  const activateModal = (pharmacyId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              id={pharmacyId}
              iconType={DeactivateModalIcon.positiveConfirmation}
              confirmationMessage={strings.areYouSureActivatePharmacy}
              initialValues={{
                id: pharmacyId,
                reason: '',
              }}
              heading={
              strings.formatString(
                strings.activateFormat,
                strings.pharmacy,
              ) as string
            }
              onSubmit={activatePharmacy}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: true,
        }),
      );
    };

  const goToAdminNotes = (pharmacyId: string) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 5,
          screenType: ScreenTypes.pharmacyList,
          extraParameters: {
            notesType: AdminNotesType.pharmacy,
          },
          pharmacyId,
        }),
      );
    };

  const actions = (params: IPharmacyListActionsParams) => {
    let arr = [
      {
        key: '1',
        label: strings.createWorkshift,
        onClick: showCreateWorkshift(params.pharmacyId, params?.email ?? ''),
      },
      {
        key: '2',
        label: strings.formatString(strings.view, strings.listOfWorkshifts),
        onClick: showListOfWorkshifts(params.pharmacyId),
      },
      {
        key: '3',
        label: strings.formatString(strings.view, strings.listOfPharmacists),
        onClick: showListOfPharmacists(params.pharmacyId),
      },
      {
        key: AppointedKeysToRemove.ViewPharmacyAdmins,
        label: strings.formatString(
          strings.view,
          strings.pharmacyAdministrators,
        ),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick: () => {},
      },
      {
        key: '5',
        label: strings.formatString(strings.view, strings.incompatibleMatch),
        onClick: showIncompatibleMatch(params.pharmacyId, params.name),
      },
      {
        key: '6',
        label: strings.adminNotes,
        onClick: goToAdminNotes(params.pharmacyId),
      },
      {
        key: Deactivate,
        label: strings.deactivate,
        onClick: deactivateModal(params.pharmacyId),
      },
      {
        key: Edit,
        label: strings.editProfileOnly,
        onClick: showEditPharmacy(params.pharmacyId),
      },
    ];

    if (cannotChangeParameters) {
      arr = arr.filter((item) =>
        item.key !== Edit);
    }
    if (cannotPost) {
      arr = arr.filter((item) =>
        item.key !== '1');
    }
    if (cannotActivate) {
      arr = arr.filter((item) =>
        item.key !== Deactivate);
    }

    return arr;
  };

  return {
    actions,
    showPharmacyProfile,
    activateModal,
  };
};

export default usePharmacyListActions;
