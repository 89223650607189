/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import GroupChannelHeader from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { GroupChannelList } from '@sendbird/uikit-react/GroupChannelList';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import '@sendbird/uikit-react/dist/index.css';
import { Box } from '@mui/system';

import {
  HelpUserTypes,
  PermissionsOfAdmin,
  userIdSelector,
  HelpTypes,
  adminName,
  setSelectedClient,
} from '@pharmaplan/common';

// eslint-disable-next-line import/no-unresolved
import { UserMessageCreateParams } from '@sendbird/chat/lib/__definition';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import useUserProfileLink from '../../../hooks/Admin/useUserProfileLink';
import ChatManager from '../../../hooks/Admin/Chat/ChatManager';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { IChannelData } from '../../../helpers/Constants';
import GroupChannelPreview from './GroupChannelPreview';

import GroupChannelTitle from './GroupChannelTitle';
import CustomMessage from './CustomMessage';
import MessageInput from './MessageInput';
import GroupHeader from './GroupHeader';
import styles from './styles';
import './style.css';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

const ChatWindow = () => {
  const dispatch = useAppDispatch();
  const { can } = useAdminPermissions();
  const canViewEngaged = can(PermissionsOfAdmin.ViewEngagedChat);

  const { assignToCurrentUser } = ChatManager();

  const [channelMetadata, setChannelMetadata] = useState<IChannelData>({});
  const [segment, setSegment] = useState<string>(HelpTypes.pharmacist);
  const [firstMessage, setFirstMessage] = useState(false);
  const [channelUrl, setChannelUrl] = useState({
    url: '',
    channelName: '',
    personnelId: '',
    userType: '',
    isEngaged: false,
  });

  const [unreadSegments, setUnreadSegments] = useState({
    [HelpTypes.pharmacist]: {},
    [HelpTypes.pharmacy]: {},
  });

  const userId = useAppSelector(userIdSelector);
  const adminUser = useAppSelector(adminName);

  const { goToUserProfile } = useUserProfileLink();

  const stub = () => {
    console.log('');
  };

  const beforeSendMessage = (params: UserMessageCreateParams) => {
    setFirstMessage(true);
    return params;
  };

  const handleChannelSelect = (channel: any) => {
    const { url, _name } = channel ?? {};
    const {
      user_type: userType,
      users,
      personnelId,
    } = channelMetadata[url] ?? {};

    const isEngaged = !!users && users !== userId;
    if (isEngaged && !canViewEngaged) return;

    setFirstMessage(false);
    dispatch(setSelectedClient(personnelId));
    setChannelUrl({
      userType,
      personnelId,
      url: url ?? '',
      channelName: _name,
      isEngaged,
    });
  };

  useEffect(() => {
    if (firstMessage) {
      assignToCurrentUser(adminUser, userId, channelUrl.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstMessage]);

  return (
    <Box sx={styles.container}>
      <GroupChannelList
        selectedChannelUrl={channelUrl.url}
        disableAutoSelect
        renderHeader={() =>
          (
            <GroupHeader
              unreadSegments={unreadSegments}
              segment={segment}
              setSegment={setSegment}
            />
          )}
        isMessageReceiptStatusEnabled
        channelListQueryParams={{ includeEmpty: true }}
        renderChannelPreview={(props) =>
          (
            <GroupChannelPreview
              channelData={channelMetadata}
              props={props}
              segment={segment}
              setChannelUrl={setChannelMetadata}
              setUnreadSegments={setUnreadSegments}
            />
          )}
        onChannelSelect={handleChannelSelect}
        onChannelCreated={stub}
      />

      {channelUrl && (
        <GroupChannel
          onBeforeSendUserMessage={beforeSendMessage}
          onChatHeaderActionClick={goToUserProfile({
            personnelId: channelUrl.personnelId,
            userType:
              HelpUserTypes[channelUrl.userType as keyof typeof HelpUserTypes],
          })}
          channelUrl={channelUrl.url}
          renderChannelHeader={(props) =>
            (
              <GroupChannelHeader
                {...props}
                renderLeft={() =>
                  <div />}
                renderMiddle={() =>
                  (
                    <GroupChannelTitle
                      isViewingEngagedChannel={channelUrl.isEngaged}
                      title={channelUrl.channelName}
                      url={channelUrl.url}
                    />
                  )}
              />
            )}
          renderMessageInput={() =>
            (
              <MessageInput isEngaged={channelUrl.isEngaged} />
            )}
          renderMessage={CustomMessage}
        />
      )}
    </Box>
  );
};

export default ChatWindow;
